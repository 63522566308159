<template>
  <div class="relative p-3 pt-0 pb-0">
    <div
      ref="cardFlipper"
      width="100%"
      height="auto"
      horizontal="true"
      class="card-member flipper mb-auto"
    >
      <img
        :src="
          memberLevel.card_layout_front ||
          require('@/assets/images/default-image.png')
        "
        alt="card member front "
        class="w-100 h-100 display-height-card"
        :class="memberLevel.card_layout_front ? '' : 'default-image'"
      />
      <div class="front">
        <img
          :src="
            memberLevel.card_layout_front ||
            require('@/assets/images/default-image.png')
          "
          alt="card member front "
          class="card-image"
          :class="memberLevel.card_layout_front ? '' : 'default-image'"
        />
        <!-- <div
          class="button-card-point"
          @click.stop="$router.push('/point')"
          :style="`color:${memberLevel.font_color};background-color:${memberLevel.bg_color}`"
        >
          <div class="ml-auto f-md">
            {{ numeralFormat(profile.total_point) }}
            <span data-lang="currency_unit">{{ $t("currency_unit") }}</span>
          </div>
          <span class="fa-stack fa-xs ml-auto">
            <font-awesome-icon
              :icon="['fas', 'circle']"
              class="fa-stack-2x member-font-color"
              style="width: 1.5em"
            /><font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="fa-stack-1x member-bg-color"
              style="width: 0.7em"
            />
          </span>
        </div> -->
      </div>
      <div class="back">
        <img
          :src="
            memberLevel.card_layout_back
              ? memberLevel.card_layout_back
              : memberLevel.card_layout_front
              ? memberLevel.card_layout_front
              : require('@/assets/images/default-image.png')
          "
          alt="card member front "
          class="card-image"
          :class="memberLevel.card_layout_back ? '' : 'default-image'"
        />
        <div class="card-member-qr" ref="cardQr" v-if="show">
          <qrcode-vue
            class="qr-width-70"
            :value="
              $store.state.theme.qr_code == 'Telephone'
                ? profile.telephone
                : profile.member_id
            "
            render-as="svg"
            background="transparent"
          />
        </div>
        <div class="text-white" v-if="show">
          <div class="f-sm" data-lang="member_expired_date">
            {{ $t("member_expired_date") }}
          </div>
          <div>{{ moment(profile.expired_date).format("DD/MM/YY") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    profile: {
      required: true
    },
    memberLevel: {
      required: true
    },
    show: {
      required: false,
      default: true
    }
  },
  data() {
    return {
      isBack: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      var style = document.createElement("style");

      style.innerHTML = `.member-font-color{ color:${this.memberLevel.font_color} }`;
      style.innerHTML += `.member-bg-color { color:${this.memberLevel.bg_color} }`;
      // style.innerHTML += `.${className} .btn-redeem { color:${item.theme_primary_color} }`;

      document.getElementsByTagName("head")[0].appendChild(style);
      // let element = this.$refs[className][0];
      // element.classList.add(className);
    });
  },
  methods: {
    flipCard() {
      if (this.$refs.cardFlipper.classList.contains("active")) {
        this.$refs.cardFlipper.classList.remove("active");
        if (this.show) this.$refs.cardQr.classList.remove("show");
      } else {
        this.$refs.cardFlipper.classList.add("active");
        if (this.show) this.$refs.cardQr.classList.add("show");
      }
    }
  }
};
</script>

<style lang="scss">
.card-member {
  > .flipper {
    background: gray;
    border-radius: 5px;
  }
}
.front,
.back {
  background-size: 100% 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  align-items: start;
}

.flipper {
  transition: all 0.75s ease 0s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;
}

.flipper.active {
  transition: all 0.75s ease 0s;
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateX(180deg);
}
.front {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  transform: rotateY(0deg);
  justify-content: end;
}
.back {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotateX(180deg) !important;
  justify-content: space-between;
}
.card-member-qr {
  transition: all 0.65s ease 0.2s;
  background-color: white;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
  opacity: 0;
}
.card-member-qr.show {
  transition: all 0.75s ease 0s;
  opacity: 1;
}
.qr-width-70 {
  width: 80px !important;
  height: 80px !important;
  // padding: 0.5rem 0;
}
.card-member:not(.active) > .back > .card-image {
  display: none;
}
.card-image {
  border-radius: 0.5rem;
  width: 100%;
  height: auto;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.button-card-point {
  // width: auto;
  min-width: 120px;
  display: flex;
  align-items: center;
  padding: 0.2rem 0.5rem;
  border-radius: 24px;
  justify-content: center;
}
.op-low {
  opacity: 0.35;
}
.display-height-card {
  visibility: hidden;
  transition: all 0.5s ease 0s;
}
.default-image {
  aspect-ratio: 16/9;
  object-fit: cover;
}
</style>
