export default [
  { brand: "Clarins", key: "Fjd8A03fXz4GrSmgk9E3to" },
  { brand: "Hourglass", key: "9FksnHozv0Fp3SdmlEriskG8" },
  { brand: "DCash", key: "8GhjfSepLreiZa239HfrkSopN" },
  { brand: "KIS", key: "M0skFu3SaVB0nDal9DkQsC" },
  { brand: "Seasun", key: "9Gfk2AkAl0BnsXloG0hErlF9s" },
  { brand: "Nissin", key: "EdkG0sz2Csk0Gk4aAi3Vn3R4" },
  { brand: "Mille", key: "g90Gsm3AlmVe0Te3RewFlso" },
  { brand: "Jewellista", key: "nB9d32Gk0NlaRwqmSpk8gGD" },
  { brand: "SilomEdge", key: "oPf0dn2A9xF0gfnV8dk2ReGk" },
  { brand: "AloThailand", key: "RoG9smCn29RaFl0BmfX9vFk" },
  { brand: "CentralBeauty", key: "9Gsk3WxAkhoH9r3TreSkfAlw" },
  { brand: "Huapla", key: "Ufn39SAlaksVmsD9Go3Ewoq" },
  { brand: "SnakeBrand", key: "0So2FmBkEwaVkk9dsGkoEp" },
  { brand: "Lolane", key: "G8djAm39FpHjErlA0bm2Ao" },
  { brand: "KCInterFoods", key: "WqmF8E03RlHoMkFd2WAox" },
  { brand: "YugenThailand", key: "m9Gk2Alp0qwFxmv9eHgju9X" },
  { brand: "Yongcharoen", key: "lg0Fm2QmzkFbX8qWGloD9sz" },
  { brand: "BanilaCo", key: "C8djs2A0GmfkAw2kG8fj0Go" },
  { brand: "GMHealthCheck", key: "F92MqloF0ppQjfiV9dk6CznLo" },
  { brand: "Thaniya", key: "X93EwlApqV2G0MxlaWeF9B" },
  { brand: "fatcat", key: "j8GdkSmzCl0FjE3QwekFoeZ" },
  { brand: "thewhoo", key: "D8cCxS0a23AkCnZlFp0QaksS" },
  { brand: "bdmswellness", key: "KD8eQlSzcmC9doEwkFnErmsD" },
  { brand: "doikham", key: "rS6ajVcCp0DskWq9FMVkcxXZX" },
  { brand: "inne", key: "M9dsAo2jCyRelS0paSm21S5Fx" },
  { brand: "ipst", key: "b8FusQoFm9BpsAXeRmGkd3TH" },
  { brand: "beautybuffet", key: "o9FjAECjSakF9lEpGrVm2EWw" },
  { brand: "costhailand", key: "u7WqVnC9OlFkdsXdNmEkKOpZ" },
  { brand: "orlebarbrownthailand", key: "W8qCmV9FsKeR0aBfM3V92gA" }
];
