import { useFavicon } from "@vueuse/core";
import { instance } from "./axios";

const icon = useFavicon();

const setDocumentTitleAndFavicon = brand => {
  document.title = `${brand.charAt(0).toUpperCase() + brand.slice(1)} - CRM`;
  icon.value =
    process.env.VUE_APP_MODE === "dev"
      ? "/images/dev.ico"
      : `/images/${brand}.ico`;
};

const getBrandFromLocation = () => {
  let brand = window.location.hostname.split("-")[0];
  return brand === "linecrm" || brand === "5t8z5pcj" ? "d-dots" : brand;
};

async function setupConfig() {
  try {
    const brand = getBrandFromLocation();

    setDocumentTitleAndFavicon(brand);

    const response = await instance.get("/api/v1/setting/getline");
  
    return response.detail;
  } catch (error) {
    console.error("Error fetching configuration:", error);
    window.alert(
      "An error occurred while setting up the configuration. Please try again later."
    );
    return null;
  }
}

export { setupConfig };
