<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div class="profile-container shadow-sm">
        <ProfileComponent
          :profile="profile"
          :userProfile="lineProfile"
          @logout="logout"
          :marketPlaceObj="marketPlaceObject"
          :memberLevel="memberLevel"
        />

        <MemberCard
          v-if="
            $store.state.theme.line_display_member_level == '1' &&
            lineLayout == 'Card'
          "
          :profile="profile"
          :memberLevel="memberLevel"
        />

        <UpcomingReward v-if="displayUpcomming" />
        <MarketPlaceBanner
          :marketPlaceObj="marketPlaceObject"
          v-if="$config.display_highlight_connect_channel == 1"
        />
        <Mission v-if="displayMission" />
        <CardConnectChannel
          :marketPlaceObj="marketPlaceObject"
          v-if="
            $store.state.theme.display_connect_channel == '1' &&
            lineLayout == 'Card'
          "
        />
      </div>

      <div class="m-3 shadow-sm" v-if="bannerFriend.length > 0">
        <v-carousel
          fade
          height="auto"
          :show-arrows="false"
          cycle
          autoplay="false"
          touch
          class="banner-friend"
          :hide-delimiters="bannerFriend.length == 1"
        >
          <v-carousel-item
            v-for="item in bannerFriend"
            :key="item.id"
            class="pointer"
          >
            <img
              class="d-block img-fluid w-100"
              :src="item.banner"
              alt="image slot"
              v-on:click="$router.push(`/invite/${item.id}?tabIndex=1`)"
            />
          </v-carousel-item>
        </v-carousel>
      </div>
      <div class="my-2">
        <div class="menulist-container shadow-sm">
          <BannerProfilePanel
            v-if="bannerList.length > 0"
            :bannerList="bannerList"
            :profile="profile"
            class="p-2"
          />
          <MenuListComponentOld :MenuList="MenuList" :profile="profile" />
        </div>
      </div>
    </div>
    <ModalLoading ref="modalLoading" />
    <ModalConfirm
      ref="ModalConfirm"
      :text="$t('confirm_logout')"
      @handler="unpairfunc"
    />
  </div>
</template>

<script>
import ProfileComponent from "@/components/profile/ProfileComponent";
import MarketPlaceBanner from "@/components/profile/MarketPlaceBanner";
import BannerProfilePanel from "@/components/profile/BannerProfilePanel";
import MenuListComponentOld from "@/components/profile/MenuListComponentOld";
import ModalCustomForm from "@/components/profile/modal/ModalCustomForm";
import ModalConfirm from "@/components/alert-modal/ModalConfirm";
import OtherLoading from "@/components/other/OtherLoading";
import Mission from "@/components/profile/Mission";
import UpcomingReward from "@/components/profile/UpComingReward";
import MemberCard from "@/components/profile/MemberCard.vue";
import CardConnectChannel from "@/components/profile/CardConnectChannel.vue";
import ModalLoading from "@/components/other/ModalLoading.vue";
export default {
  components: {
    ProfileComponent,
    ModalCustomForm,
    ModalConfirm,
    OtherLoading,
    MenuListComponentOld,
    BannerProfilePanel,
    Mission,
    UpcomingReward,
    MemberCard,
    CardConnectChannel,
    ModalLoading,
    MarketPlaceBanner
  },
  mounted() {},
  data() {
    return {
      MenuList: [],
      profile: { ...this.$store.state.shortProfile },
      lineProfile: this.$cookies.get("user_profile_token"),
      isLoading: true,
      filter: {
        SocialProvider: "line",
        SocialId: ""
      },
      privilegeList: [],
      notFoundData: false,
      rewardList: [],
      filterNews: {
        page: 1,
        take: 10
      },
      newsList: [],
      bannerList: [],
      FGFList: [],
      filterBanner: {
        level_id: 1
      },
      marketPlaceObject: [],
      bannerFriend: [],
      customFormList: [],
      bannerIndex: 0,
      memberLevel: {}
    };
  },

  async mounted() {
    this.getProfileDetail();
  },
  methods: {
    async getBannerGetFriend() {
      const res = await this.$axios(`/api/v1/FriendGetFriend/get`);
      this.bannerFriend = res.detail;
    },

    async getBanner() {
      await this.$axios
        .get(`/api/v1/banner/campaign_form`)
        .then(async data => {
          if (data.result == 1) {
            if (data.detail.totalCount > 0) {
              this.bannerList = data.detail.detail;
            }
          }
        })
        .catch(error => {
          console.log("getBanner", error.message);
        });
    },

    async unpairfunc() {
      try {
        this.filter.SocialId = this.lineProfile.userId;
        this.$refs.modalLoading.show();
        const data = await this.$axios.post(`/api/v1/user/unpair`, this.filter);

        if (data.result == 1) {
          this.$cookies.remove("token");
          this.$cookies.remove("hourglass_register_success");
          this.$cookies.remove("popupModalRegister");
          // this.$store.commit("setShortProfile", {
          //   id_card: "",
          //   prefix: "",
          //   first_name_th: "",
          //   first_name_en: "",
          //   last_name_th: "",
          //   last_name_en: "",
          //   email: "",
          //   telephone: "",
          //   gender: "",
          //   birthday: "",
          //   birthday_day: "",
          //   birthday_month: "",
          //   birthday_year: "",
          //   nickname: "",
          //   nickname_en: "",
          //   country: "",
          //   home_address: "",
          //   town: "",
          //   alley: "",
          //   road: "",
          //   subdistrict: "",
          //   district: "",
          //   province: "",
          //   zip_code: "",
          //   optional_telephone: "",
          //   id_card_image: "",
          //   facebook: "",
          //   x: "",
          //   is_company: "",
          //   company_type: "",
          //   company_branch_type: "",
          //   company_branch_no: "",
          //   company_branch: "",
          //   passport_no: ""
          // });
          setTimeout(() => {
            this.$refs.modalLoading.hide();
            this.$router.replace("/validatetelephone");
          }, 500);
        } else window.alert("Internal Server Error.");
      } catch (error) {
        this.$cookies.remove("token");
        this.$cookies.remove("hourglass_register_success");
        this.$cookies.remove("popupModalRegister");
        this.$refs.modalLoading.hide();
        this.$router.replace("/validatetelephone");
      }
    },
    logout() {
      this.$refs.ModalConfirm.show();
    },
    async getProfileDetail() {
      this.memberTierDetail(this.profile.member_level_id);
      this.getBanner();
      this.getMenuList();
      this.getBannerGetFriend();
      this.getMarketPlace();
      // const promise = async () =>
      //   Promise.all(() => [
      //     Promise.resolve(this.memberTierDetail(this.profile.member_level_id)),
      //     Promise.resolve(this.getBanner()),
      //     Promise.resolve(this.getMenuList()),
      //     Promise.resolve(this.getBannerGetFriend()),
      //     Promise.resolve(this.getMarketPlace())
      //   ]);
      // await promise();
      this.isLoading = false;
    },

    async getMarketPlace() {
      try {
        const data = await this.$axios(`/api/v1/User/get/marketplace`);

        this.marketPlaceObject = data.detail;
      } catch (error) {
        console.log("getMarketPlace", error.message);
      }
    },
    async getMenuList() {
      try {
        const data = await this.$axios(
          `/api/v1/setting/display_language/customer_menu`
        );
        this.MenuList = data.detail.filter(el => el.display == 1);
      } catch (error) {
        console.log("getMenu", error.message);
      }
    },

    async memberTierDetail(id) {
      this.memberLevel = this.$store.state.shortProfile.member_list.find(
        el => el.is_default == 1
      );
      this.memberLevel.upgrade_tier_name =
        this.memberLevel.upgrade_tier_id == 0
          ? ""
          : this.$store.state.shortProfile.member_list.find(
              a => a.id == this.memberLevel.upgrade_tier_id
            ).member_level_language[0].display;
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-container {
  position: relative;
  background: white;
  margin: 1rem;
  border-radius: 5px;
}

.menulist-container {
  background: white;
  border-radius: 5px;
  margin: 1rem; // padding-bottom: 100px;
}

.line-text-pf {
  border-left: 6px solid black;
}

.btn-unpair {
  background-color: transparent;
  border: none;
  color: #bebebe;
}

.w-90 {
  width: 90%;
}

.banner-friend {
  img {
    border-radius: 5px;
    aspect-ratio: 16/9;
  }
}
</style>
