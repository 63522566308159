<template>
  <v-row class="m-0 bg-card-connect align-items-center">
    <v-col cols="6">
      <div class="f-sm">
        {{ $t("way_to_earn") }} <br />
        {{ $t("free_point") }}
      </div>
    </v-col>
    <v-col>
      <div class="marketplace-panel">
        <template v-for="(market_list, index) of marketPlaceObj.marketplace">
          <div
            class="card-marketplace-icon"
            v-if="index < 4"
            :key="
              market_list.shopName + market_list.branchId + '' + market_list.id
            "
            @click="to(market_list)"
          >
            <img
              :src="
                market_list.marketplaceUrl ||
                'https://api-hourglass-admin-dev.dosetech.co/media/NewsImage/231026021009526.jpg'
              "
              alt="Marketplace icon"
              :class="[market_list.status ? '' : 'op-low']"
            />
          </div>
        </template>
      </div>
    </v-col>
    <v-col cols="1" @click="$router.push('/marketplace')">
      <font-awesome-icon
        :icon="['fas', 'chevron-right']"
        class="highlight-color cursor-pointer f-sm"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    marketPlaceObj: {
      required: false
    }
  },
  methods: {
    to(market_list) {
      if (market_list.salesChannelId != 1)
        this.$router.push(
          `/connectmarketplace?b_id=${market_list.branchId}&s_id=${market_list.salesChannelId}`
        );
    }
  }
};
</script>

<style lang="scss">
.card-marketplace-icon {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 50%;
  }
  .cover {
    background: #858585;
    opacity: 0.5;
    position: absolute;
    top: 0;
    height: 30px;
    width: 30px;
    left: 0;
    border-radius: 50%;
  }
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* overflow: hidden; */
  position: relative;
  padding: 1px;
  // margin-top: 2px;
}
.marketplace-panel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-end;
}
.bg-card-connect {
  background-color: rgb(241, 241, 241);
  color: rgb(176, 176, 176);
  padding: 0 0.5rem;
}
</style>
