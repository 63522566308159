<template>
  <div>
    <v-dialog
      v-model="showModal"
      ref="modalError"
      hide-header
      hide-footer
      centered
      @hidden="hide"
      no-close-on-backdrop
    >
      <template v-if="lineLayout == 'Compact'">
        <div class="text-center mb-4">
          <template v-if="text">
            <p class="f-xl mb-0 f-bold" v-if="useHtmlText" v-html="text"></p>
            <p class="f-xl mb-0 f-bold" v-else>{{ text }}</p>
          </template>
          <template v-if="desc">
            <p class="f-sm mb-0" v-if="useHtmlText">{{ desc }}</p>
            <p class="f-sm mb-0" v-else>{{ desc }}</p>
          </template>
        </div>
        <div class="text-center mb-4" v-if="!hideIcon">
          <font-awesome-icon
            icon="times-circle"
            size="5x"
            style="color: var(--primary-color)"
          />
        </div>
      </template>
      <template v-else>
        <div class="text-center" v-if="!hideIcon">
          <font-awesome-icon
            icon="times-circle"
            size="5x"
            class="mb-4"
            style="color: var(--primary-color)"
          />
        </div>
        <div class="text-center">
          <template v-if="text">
            <p class="f-xl mb-0 f-bold" v-if="useHtmlText">{{ text }}</p>
            <p class="f-xl mb-0 f-bold" v-else>{{ text }}</p>
          </template>
          <template v-if="desc">
            <p class="f-sm mb-0" v-if="useHtmlText">{{ desc }}</p>
            <p class="f-sm mb-0" v-else>{{ desc }}</p>
          </template>
        </div>
        <template v-if="note">
          <div>
            <span class="f-bold f-md">{{ $t("note") }} *</span> <br /><span
              class="f-sm"
              >{{ note }}</span
            >
          </div>
        </template>
      </template>
      <hr />

      <c-btn @click="hide" variant="confirm-primary" block>
        {{ $t("close_window") }}
      </c-btn>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    },
    hideIcon: {
      required: false,
      default: false
    },
    useHtmlText: {
      default: false,
      required: false,
      type: Boolean
    },
    desc: { required: false, type: String },
    note: {
      required: false,
      default: ""
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.$emit("show");
    },
    hide() {
      this.showModal = false;
      this.$emit("hide");
    }
  }
};
</script>

<style rel="scss" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.modal-body {
  margin: auto;
  text-align: center;
}
</style>
