<template>
  <div>
    <v-row no-gutters style="row-gap: 10px" v-if="!isLoading">
      <template
        v-for="(profile, index) in fields"
        :key="'profile-field' + index"
      >
        <v-col cols="12" v-if="profile.is_address_display != false">
          <div :set="(field = v$.form[checkIsProfile(profile[fieldId])])">
            <div
              v-if="
                profile[fieldId] == 18 &&
                checkIsProfile(profile[fieldId]) == 'branch_type'
              "
            >
              <div class="input-custom">
                <label>
                  {{ profile.name }}
                  <span v-if="profile.required" class="text-danger">*</span>
                </label>

                <InputRadio
                  :id="`${profile[fieldId]}`"
                  :options="profile.field_choices"
                  value-field="name"
                  text-field="display_name"
                  v-model:value="form[checkIsProfile(profile[fieldId])]"
                  :v="field"
                  :validateText="profile.validate_text"
                  :isValidate="field.$error"
                ></InputRadio>
                <template
                  v-if="form[checkIsProfile(profile[fieldId])] == 'สาขา'"
                >
                  <InputText
                    v-if="!isLoading"
                    textFloat=""
                    placeholder="ชื่อสาขา"
                    class="margin-input f-regular"
                    :type="checkFieldConditon(profile)"
                    name="name-branch"
                    size="lg"
                    v-model:value="profile.company_branch"
                    :isRequired="profile.required"
                  />
                  <InputText
                    v-if="!isLoading"
                    textFloat=""
                    placeholder="รหัสสาขา"
                    class="margin-input f-regular mt-2"
                    :type="checkFieldConditon(profile)"
                    name="name-branch"
                    size="lg"
                    v-model:value="profile.company_branch_no"
                    :isRequired="profile.required"
                  />
                </template>
              </div>
            </div>
            <div v-else-if="isTelephone() == profile[fieldId]">
              <div class="content-between">
                <label class="label-text mb-2"
                  >เบอร์โทรศัพท์
                  <span class="remark text-danger" v-if="profile.required"
                    >*</span
                  >
                </label>
                <div
                  @click="$emit('addOptionalTelephone')"
                  v-if="form.telephone.length >= 9"
                >
                  <small
                    ><u data-lang="optional_telephone">{{
                      $t("optional_telephone")
                    }}</u></small
                  >
                </div>
              </div>

              <InputTelephonWithCountry
                v-model:value="form[checkIsProfile(profile[fieldId])]"
                :isValidate="field.$error"
                :v="field"
              />
            </div>
            <div v-else-if="profile[fieldId] == defaultId"></div>
            <div v-else-if="profile.field_type_id == 3">
              <div class="input-custom">
                <label>
                  {{ profile.name }}
                  <span v-if="profile.required" class="text-danger">*</span>
                </label>
                <InputRadio
                  :id="`${profile[fieldId]}`"
                  :options="profile.field_choices"
                  value-field="name"
                  text-field="display_name"
                  v-model:value="form[checkIsProfile(profile[fieldId])]"
                  :v="field"
                  :validateText="profile.validate_text"
                  :isValidate="field.$error"
                  @change="val => handleBillingType(val, profile)"
                ></InputRadio>
              </div>
            </div>
            <div v-else-if="profile.field_type_id == 2">
              <div class="input-custom">
                <label>
                  {{ profile.name }}

                  <span v-if="profile.required" class="text-danger">*</span>
                </label>

                <InputCheckbox
                  :id="`${profile.id}`"
                  :options="[{ name: 0 }, { name: 1 }]"
                  value-field="name"
                  v-model:value="form[checkIsProfile(profile[fieldId])]"
                  :value="form[checkIsProfile(profile[fieldId])]"
                  text-field="name"
                  stacked
                  :v="field"
                  :validateText="profile.validate_text"
                  :isValidate="field.$error"
                />
              </div>
            </div>
            <div v-else-if="profile.field_type_id == 4" class="margin-input">
              <v-row class="no-gutters px-1">
                <v-col cols="12" class="pb-0">
                  <label class="mb-0">
                    {{ changeName(profile) }}
                    <span v-if="profile.required" class="text-danger">*</span>
                  </label>
                </v-col>
                <v-col>
                  <InputSelect
                    title=""
                    :text="changeName(profile)"
                    :name="changeName(profile)"
                    :placeholder="changeName(profile)"
                    valueField="name"
                    textField="display_name"
                    v-bind:options="
                      profile.field_choices.filter(el => el.is_display == 1)
                    "
                    v-model:value="form[checkIsProfile(profile[fieldId])]"
                    :isRequired="profile.required"
                    class="f-regular"
                    :isValidate="field.$error"
                    :v="field"
                    @deleteOtherValue="deleteOtherValue(profile)"
                    @setOtherValue="
                      val => {
                        profile.other_value = val;
                      }
                    "
                    @change="val => setOtherValue(val, profile)"
                    formType
                  />
                </v-col>

                <v-col v-if="isOtherField(profile)">
                  <InputText
                    textFloat=""
                    :placeholder="otherName(profile)"
                    class="margin-inputf-regular"
                    :type="checkFieldConditon(profile)"
                    name="firstname"
                    size="lg"
                    v-model:value="profile.other_value"
                    :maxlength="checkMaxMin(profile.field_conditions, 2)"
                    :min="checkMaxMin(profile.field_conditions, 3)"
                    :max="checkMaxMin(profile.field_conditions, 4)"
                    :isValidate="field.$error"
                    :disabled="profile.field_profile_type_id == 10"
                    :v="field"
                    @onDataChange="isMaxMin"
                    @onkeypress="isNumber"
                  />
                </v-col>
              </v-row>
            </div>
            <div v-else-if="profile.field_type_id == 15" class="margin-input">
              <InputSelect
                :title="changeName(profile)"
                :text="changeName(profile)"
                :name="changeName(profile)"
                :placeholder="changeName(profile)"
                valueField="name"
                textField="name"
                v-bind:options="profile.field_choices"
                v-model:value="form[checkIsProfile(profile[fieldId])]"
                @change="val => handleChange(val, profile)"
                :isRequired="profile.required"
                class="f-regular"
                :isValidate="field.$error"
                :v="field"
                :disabled="disabledDependentField(profile)"
                formType
              />
            </div>
            <div v-else-if="profile.field_type_id == 7">
              <v-row>
                <v-col cols="12">
                  <label for="" class="custom-label">
                    {{ profile.field_language.name }}
                    {{ `(${year} ${yearFormat == 1 ? $t("be") : $t("ad")})` }}
                    <span v-if="profile.required" class="text-danger">* </span>
                  </label>
                </v-col>

                <v-col>
                  <v-select
                    v-model="form.birthday_day"
                    :model-value="form.birthday_day || null"
                    :items="day"
                    :placeholder="$t('day')"
                    @change="handleDayChange"
                    variant="outlined"
                    single-line
                    hide-details
                    :error="v$.form.birthday_day.$error"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item value="" disabled selected>
                        <span>
                          <slot name="options-name">$t('month')</slot>
                        </span>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                    v-model="form.birthday_month"
                    :model-value="form.birthday_month || null"
                    :items="month"
                    @change="handleMonth"
                    :placeholder="$t('month')"
                    variant="outlined"
                    single-line
                    hide-details
                    :error="v$.form.birthday_month.$error"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item value="" disabled selected>
                        <span>
                          <slot name="options-name">$t('month')</slot>
                        </span>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>

                <v-col>
                  <InputText
                    v-model:value="form.birthday_year"
                    textFloat=""
                    :placeholder="`${year} ${
                      yearFormat == 1 ? $t('be') : $t('ad')
                    }`"
                    :isValidate="v$.form.birthday_year.$error"
                    type="number"
                    name="birthday_year"
                    @input="e => handleBirthYear(e.target.value, e)"
                    @onPaste="handleBirthYear"
                  />
                </v-col>
                <v-col
                  cols="12"
                  v-if="
                    v$.form.birthday_year.$error ||
                    v$.form.birthday_month.$error ||
                    v$.form.birthday_day.$error
                  "
                >
                  <div style="margin-top: -15px">
                    <span class="text-error">{{
                      $t("default_input_validate")
                    }}</span>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-else-if="profile.field_type_id == 8">
              <label v-if="changeName(profile)">
                {{ changeName(profile) }}

                <span v-if="profile.required" class="text-danger">*</span>
              </label>

              <FormUploadImage
                :id="profile[fieldId]"
                :label="changeName(profile)"
                :img="form[checkIsProfile(profile[fieldId])]"
                v-model:value="form[checkIsProfile(profile[fieldId])]"
                :v="field"
                :validateText="field.validate_text"
                :isValidate="field.$error && field.$error"
                @deleteImage="deleteImage(index)"
              />
              <div v-if="field.$error && field.$error">
                <small class="text-error">{{ field.validate_text }}</small>
              </div>
            </div>
            <div v-else-if="profile.field_type_id == 16" class="margin-input">
              <v-row class="no-gutters px-1">
                <v-col cols="12" class="pb-0">
                  <label class="mb-0">
                    {{ changeName(profile) }}
                    <span v-if="profile.required" class="text-danger">*</span>
                  </label>
                </v-col>
                <v-col>
                  <InputSelect
                    title=""
                    :text="changeName(profile)"
                    :name="changeName(profile)"
                    :placeholder="changeName(profile)"
                    valueField="englishName"
                    textField="englishName"
                    v-bind:options="country"
                    v-model:value="form.country"
                    :isRequired="profile.required"
                    class="f-regular"
                    :isValidate="field.$error"
                    :v="field"
                    @change="val => changeLocale(val, true)"
                  />
                </v-col>
              </v-row>
            </div>
            <div v-else-if="profile.field_type_id == 12">
              <InputTextArea
                :id="field.id"
                :textFloat="changeName(profile)"
                :placeholder="changeName(profile)"
                type="text"
                class="mt-3 custom-input"
                rows="3"
                v-model:value="form[checkIsProfile(profile[fieldId])]"
                :isRequired="profile.required"
                :isValidate="field.$error"
                :v="field"
              />
            </div>
            <div
              v-else-if="profile.field_type_id == 1 && isNotOptional(profile)"
            >
              <template
                v-if="
                  fieldId == 'field_billing_type_id' &&
                  isTelephone2() == profile[fieldId]
                "
              >
                <InputTelephonWithCountry
                  :textFloat="changeName(profile)"
                  :placeholder="changeName(profile)"
                  v-model:value="form[checkIsProfile(profile[fieldId])]"
                  :isValidate="field.$error"
                  :v="field"
                  :isRequired="profile.required"
                />
              </template>
              <template v-else>
                <InputText
                  v-if="!isLoading"
                  :textFloat="changeName(profile)"
                  :placeholder="changeName(profile)"
                  class="margin-inputf-regular"
                  :type="checkFieldConditon(profile)"
                  :name="changeName(profile)"
                  size="lg"
                  v-model:value="form[checkIsProfile(profile[fieldId])]"
                  :maxlength="checkMaxMin(profile.field_conditions, 2)"
                  :min="checkMaxMin(profile.field_conditions, 3)"
                  :max="checkMaxMin(profile.field_conditions, 4)"
                  :isRequired="profile.required"
                  :isValidate="field.$error"
                  :v="field"
                  @onDataChange="isMaxMin"
                  @onkeypress="isNumber"
                />
              </template>
            </div>
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import InputTextArea from "@/components/input/InputTextArea";
import InputTelephonWithCountry from "@/components/input/InputTelephoneWithCountry.vue";
import FormUploadImage from "@/components/input/FormUploadImage";
const country = require("@/lang/country.json");
export default {
  components: {
    FormUploadImage,
    InputTextArea,
    InputTelephonWithCountry
  },
  props: {
    fields: { required: true },
    form: { required: true },
    mapField: { required: true },
    fieldId: {
      required: true
    },
    v$: {
      required: false
    },
    defaultId: {
      required: false
    },
    cloneFields: {
      required: false
    },
    companyPrefix: {
      required: false
    }
  },
  computed: {
    fieldsList() {
      return this.fields;
    }
  },
  watch: {
    fieldsList(val) {
      if (this.fieldId == "field_billing_type_id")
        this.handleBillingType(
          this.form.billing_address_type,
          this.fieldsList.find(el => el[this.fieldId] == 1)
        );
    }
  },
  async mounted() {
    this.loadingField();
  },
  data() {
    return {
      isLoading: false,
      day: [
        { value: "01", title: "01", disabled: false },
        { value: "02", title: "02", disabled: false },
        { value: "03", title: "03", disabled: false },
        { value: "04", title: "04", disabled: false },
        { value: "05", title: "05", disabled: false },
        { value: "06", title: "06", disabled: false },
        { value: "07", title: "07", disabled: false },
        { value: "08", title: "08", disabled: false },
        { value: "09", title: "09", disabled: false },
        { value: "10", title: "10", disabled: false },
        { value: "11", title: "11", disabled: false },
        { value: "12", title: "12", disabled: false },
        { value: "13", title: "13", disabled: false },
        { value: "14", title: "14", disabled: false },
        { value: "15", title: "15", disabled: false },
        { value: "16", title: "16", disabled: false },
        { value: "17", title: "17", disabled: false },
        { value: "18", title: "18", disabled: false },
        { value: "19", title: "19", disabled: false },
        { value: "20", title: "20", disabled: false },
        { value: "21", title: "21", disabled: false },
        { value: "22", title: "22", disabled: false },
        { value: "23", title: "23", disabled: false },
        { value: "24", title: "24", disabled: false },
        { value: "25", title: "25", disabled: false },
        { value: "26", title: "26", disabled: false },
        { value: "27", title: "27", disabled: false },
        { value: "28", title: "28", disabled: false },
        { value: "29", title: "29", disabled: false },
        { value: "30", title: "30", disabled: false },
        { value: "31", title: "31", disabled: false }
      ],
      month: [
        { value: "01", title: "มกราคม", disabled: false },
        { value: "02", title: "กุมภาพันธ์", disabled: false },
        { value: "03", title: "มีนาคม", disabled: false },
        { value: "04", title: "เมษายน", disabled: false },
        { value: "05", title: "พฤษภาคม", disabled: false },
        { value: "06", title: "มิถุนายน", disabled: false },
        { value: "07", title: "กรกฎาคม", disabled: false },
        { value: "08", title: "สิงหาคม", disabled: false },
        { value: "09", title: "กันยายน", disabled: false },
        { value: "10", title: "ตุลาคม", disabled: false },
        { value: "11", title: "พฤศจิกายน", disabled: false },
        { value: "12", title: "ธันวาคม", disabled: false }
      ],
      selectLoading: false,
      country
    };
  },

  methods: {
    checkFieldConditon(obj) {
      return obj.field_conditions[0]
        ? obj.field_conditions[0].field_condition_type_id == 1
          ? obj.field_conditions[0].value
          : "text"
        : "text";
    },
    checkIsProfile(val) {
      const findField = this.mapField.find(el => el.id == val);
      return findField ? findField.key : false;
    },
    checkMaxMin(item, key, field_id) {
      if (field_id == 9) {
        const condition = item.find(el => el.field_condition_type_id == 7);

        if (condition)
          if (key == 3) {
            return condition.value
              ? this.$moment()
                  .subtract(condition.value, "year")
                  .format("YYYY-01-01")
              : false;
          } else if (key == 4) return this.$moment().format("YYYY-MM-DD");
      }
      const conditon = item.find(el => el.field_condition_type_id == key);
      if (conditon) return conditon.value ? conditon.value : false;
      return false;
    },
    isMaxMin: function (value, e) {
      try {
        const val = e.target.value;

        const max = Number(e.target.max);
        const min = Number(e.target.min);
        if (val && e.target.type == "number" && min && val < min) {
          return (e.target.value = min);
        }
        if (val && e.target.type == "number" && max && val > max) {
          return (e.target.value = max);
        }
      } catch (error) {
        console.log(error);
      }
    },
    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        e.target.type == "number"
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },

    async handleChange(val, field) {
      let payload = field.field_choices.find(el => el.name == val);
      let $keyset = this.checkIsProfile(field[this.fieldId]);

      if (payload) {
        let $set = this.fields.findIndex(
          el => el[this.fieldId] == field.field_dependent
        );

        if ($keyset == "province") {
          let $set1 = this.fields.findIndex(el => el[this.fieldId] == 16);
          let sub_district = this.mapField.find(
            el => el.key == "sub_district"
          ).id;
          let $set2 = this.fields.findIndex(
            el => el[this.fieldId] == sub_district
          );
          this.fields[$set1].field_choices = [];
          this.fields[$set2].field_choices = [];
          this.form.district = "";
          this.form.sub_district = "";
          this.form.zip_code = "";
        }
        if ($keyset == "district") {
          this.form.sub_district = "";
          this.form.zip_code = "";
        }
        if ($keyset == "subdistrict" || $keyset == "sub_district") {
          this.form.zip_code = "";
        }
        if (this.fields[$set].field_type_id == 1)
          this.form[this.checkIsProfile(this.fields[$set][this.fieldId])] =
            payload.zip_code;
        else {
          const result = await this.$axios(
            `/api/v1/Service/Getsubaddress/${payload.id}/${
              $keyset == "province" ? 1 : 2
            }`
          );
          let fieldKey = this.checkIsProfile(this.fields[$set][this.fieldId]);
          this.fields[$set].field_choices =
            result.detail[
              fieldKey == "sub_district" ? "subdistrict" : fieldKey
            ];
        }
      }
    },
    async setField(val, field, payload) {
      let $keyset = this.checkIsProfile(field[this.fieldId]);

      if (payload) {
        let $set = this.fields.findIndex(
          el => el[this.fieldId] == field.field_dependent
        );

        if (this.fields[$set].field_type_id == 1)
          this.form[this.checkIsProfile(this.fields[$set][this.fieldId])] =
            payload.zip_code;
        else {
          const result = await this.$axios(
            `/api/v1/Service/Getsubaddress/${payload.id}/${
              $keyset == "province" ? 1 : 2
            }`
          );
          let fieldKey = this.checkIsProfile(this.fields[$set][this.fieldId]);
          this.$nextTick(
            () =>
              (this.fields[$set].field_choices =
                result.detail[
                  fieldKey == "sub_district" ? "subdistrict" : fieldKey
                ])
          );
        }
      }
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    handleBirthYear(val, e) {
      let year = this.$moment().year();
      if (val > year) {
        e.target.value = year;
        this.form.birthday_year = year;
      }
    },
    editPhone() {
      this.$store.commit("setEditPhoneNumber", true);
      this.$router.push({
        path: "/telephone",
        query: { tel: this.form.telephone }
      });
    },

    disabledDependentField($field) {
      let id = $field[this.fieldId];

      let $disable = this.fields.find(el => el[this.fieldId] == id);

      if ($field.field_choices)
        if ($disable && $field.field_choices.length > 0) return false;
      return $disable ? true : false;
    },
    changeName($fields) {
      if ($fields.field_language[0])
        return $fields.field_language[0].name || $fields.name;
      return $fields.name;
    },
    async changeLocale(val, clearField = false) {
      let keyAddress = [
        "address",
        "zip_code",
        "sub_district",
        "district",
        "province",
        "road",
        "alley",
        "building_name"
      ];
      let changeFieldType = ["sub_district", "district", "province"];
      let addressInput = this.mapField.find(el => el.key == "address").id;
      let provinceInput = this.mapField.find(el => el.key == "province").id;
      let choiceValue = val != "ประเทศไทย";
      let changeField = this.mapField
        .filter(el => changeFieldType.includes(el.key))
        .map(el => el.id);
      let addressField = this.mapField
        .filter(el => keyAddress.includes(el.key))
        .map(el => el.id);
      let index = 0;
      for (const fields of this.fields) {
        if (addressField.includes(fields[this.fieldId])) {
          if (choiceValue) {
            // this.form[this.checkIsProfile(fields[this.fieldId])] = "";
            if (changeField.includes(fields[this.fieldId]))
              fields.field_type_id = 1;
          } else {
            if (changeField.includes(fields[this.fieldId]))
              fields.field_type_id = 15;
          }
          fields.is_address_display = true;
          fields.required = choiceValue
            ? false
            : this.cloneFields[index].required;
          if (clearField) {
            this.form[this.checkIsProfile(fields[this.fieldId])] = "";

            if (!choiceValue)
              if (changeField)
                if (fields[this.fieldId] == provinceInput)
                  fields.field_choices = await this.$store.dispatch(
                    "getProvince"
                  );
                else fields.field_choices = [];
          }
        }

        index++;
      }
    },
    isTelephone() {
      let optionalField = this.mapField.find(
        el => el.key == "optional_telephone"
      );
      return optionalField
        ? this.mapField.find(el => el.key == "telephone").id
        : false;
    },
    isTelephone2() {
      return this.mapField.find(el => el.key == "telephone").id;
    },
    isNotOptional(profile) {
      let optionalField = this.mapField.find(
        el => el.key == "optional_telephone"
      );
      if (optionalField)
        return profile[this.fieldId] == optionalField.id ? false : true;
      return true;
    },
    deleteOtherValue(profile) {
      delete profile.other_value;
    },
    otherName($fields) {
      return $fields.other_name || $fields.name;
    },
    setOtherValue(val, profile) {
      if (val.type) return;
      if (val) {
        let desc = profile.field_choices.find(el => el.name == val);
        if (desc) profile.other_name = desc.description;
      }
    },
    isOtherField($fields) {
      if ($fields) {
        let find = $fields.field_choices.find(
          el => el.name == this.form[this.checkIsProfile($fields[this.fieldId])]
        );
        return find ? (find.choice_type_id == 3 ? true : false) : false;
      }
    },
    async loadingField() {
      this.isLoading = true;

      this.$nextTick(async () => {
        for (const $fields of this.fields) {
          let value = this.form[this.checkIsProfile($fields[this.fieldId])];

          if ($fields.field_type_id == 15) {
            if (value) {
              let payload = await $fields.field_choices.find(
                el => el.name == value
              );

              await this.setField(value, $fields, payload);
            }
          }
        }
        await this.changeLocale(this.form.country);
      });
      this.isLoading = false;
    },
    async handleBillingType(val, field, clear = true) {
      this.selectLoading = true;
      let f = this.mapField.find(el => field[this.fieldId] == el.id);

      if (f.key == "billing_address_type") {
        let clearField = [];
        let keyHide = [];
        let keyName = [];
        let showKey = [];
        if (val == "บุคคลธรรมดา") {
          keyHide = ["branch_type"];
          showKey = ["last_name"];
          keyName = [
            {
              text: "คำนำหน้า",
              key: "3"
            },
            { text: "ชื่อ", key: "4" }
          ];
        } else if (val == "นิติบุคคล") {
          keyHide = ["last_name"];
          showKey = ["branch_type"];
          keyName = [
            {
              text: "คำนำหน้าธุรกิจ",
              key: "3",
              choice: this.companyPrefix.field_choices
            },
            { text: "ชื่อธุรกิจ", key: "4" }
          ];
        }

        let hideField = this.mapField
          .filter(el => keyHide.includes(el.key))
          .map(el => el.id);
        let showField = this.mapField
          .filter(el => showKey.includes(el.key))
          .map(el => el.id);
        this.$nextTick(() => {
          if (hideField.length > 0) {
            let index = 0;
            for (const fields of this.fields) {
              let changeName = keyName.find(
                el => el.key == fields[this.fieldId]
              );
              let key = this.checkIsProfile(fields[this.fieldId]);
              if (changeName) {
                if (clear) this.form[key] = "";
                fields.name = changeName.text;
                fields.field_choices =
                  changeName.choice || this.cloneFields[index].field_choices;
              }
              if (hideField.includes(fields[this.fieldId])) {
                if (clear) this.form[key] = "";
                fields.required = false;
                fields.is_address_display = false;
              } else if (showField.includes(fields[this.fieldId])) {
                if (clear)
                  this.form[key] = key == "branch_type" ? "สำนักงานใหญ่" : "";
                fields.required = this.cloneFields[index].required;
                fields.is_address_display = true;
              }
              index++;
            }
          }
        });
      }
      this.selectLoading = false;
    }
  }
};
</script>

<style>
.box-tel {
  color: #575757;
  margin: 0;
  height: 45px;
  background: white;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
}
</style>
