<template>
  <div>
    <label v-if="textFloat" :class="className">
      {{ textFloat }} <span class="text-remark">{{ textRemark }}</span>

      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <b-row class="align-items-center">
      <b-col cols="12" :sm="countryShow ? 8 : 12" class="d-inline-flex w-100">
        <InputText
          textFloat=""
          type="number"
          v-model:value="telephone"
          name="telephone"
          @onKeypress="isNumberKey"
          :placeholder="$t('telephone')"
          @input="val => input(val)"
          :isValidate="isValidate"
          inputmode="numeric"
          :v="v"
          class="flex-shrink"
        />
        <InputText
          textFloat=""
          type="number"
          v-model:value="ext"
          name="Ext. telephone"
          class="pl-2 flex-shrink-2"
          @onKeypress="isNumberKey"
          :disabled="telephone == 0"
          :placeholder="$t('ext')"
          @input="val => joinTelephone(val)"
        />
        <c-btn
          style="margin: 0 0 15px 5px; min-width: 30px"
          variant="custom-link"
          @click="$emit('delete')"
          v-if="!countryShow && !removeIcon"
          ><font-awesome-icon icon="trash-alt"></font-awesome-icon
        ></c-btn>
      </b-col>
      <!-- <b-col> </b-col> -->
    </b-row>
  </div>
</template>

<script>
export default {
  name: "InputTelephoneWithCountry",
  props: {
    textFloat: { required: false, Type: String },
    country: {
      required: false
    },
    value: {
      required: true
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    placeholder: {
      required: false,
      default: ""
    },
    textValidate: {
      required: false,
      default: "Please input value."
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object || null || undefined
    },
    countryShow: {
      required: false,
      default: false,
      type: Boolean
    },
    removeIcon: {
      required: false,
      default: true
    }
  },
  data() {
    return {
      countryList: [],
      ext: "",
      telephone: ""
    };
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal) {
          this.ext = newVal.split("-")[1];
          this.telephone = newVal.split("-")[0];
        }
      },
      immediate: true
    }
  },

  created() {
    if (this.countryShow) this.getCountry();
  },
  methods: {
    async getCountry() {
      const res = await this.$axios("/setting/GetTelephoneCountry");

      this.countryList = res.data.detail.map(el => {
        return { name: `${el.name} (${el.dial_code})`, value: el.id };
      });
    },
    isNumberKey(e) {
      var charCode = e.which ? e.which : e.keyCode;

      if (
        !this.countryShow &&
        charCode > 31 &&
        (charCode < 43 ||
          charCode > 57 ||
          charCode == 46 ||
          charCode == 45 ||
          charCode == 47)
      ) {
        e.preventDefault();
        return;
      } else if (
        this.countryShow &&
        charCode > 31 &&
        (charCode < 48 || charCode > 57)
      ) {
        e.preventDefault();
        return;
      }
      return;
    },
    input(e) {
      if (e.target.value.length > 10) {
        e.target.value = e.target.value.slice(0, 10);
        this.telephone = e.target.value;
        return e.preventDefault();
      }
      this.joinTelephone();
    },
    joinTelephone(val) {
      if (this.telephone.length == 0) this.ext = "";
      let value = `${this.telephone}${this.ext ? "-" + this.ext : ""}`;
      this.$emit("update:value", value);
    },
    onChange(val) {
      this.telephone = "";
      this.ext = "";
      let value = `${this.telephone}${this.ext ? "-" + this.ext : ""}`;
      this.$emit("update:value", value);
      this.$emit("set", val);
    }
  }
};
</script>

<style>
.flex-shrink-2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}
.flex-shrink {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
}
</style>
