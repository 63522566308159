<template>
  <div class="px-2 pb-2" v-if="showReward">
    <div class="content-between mb-2">
      <div class="f-lg color-primary f-bold">{{ title }}</div>
      <div class="color-primary f-sm" @click="$router.push('/reward')">
        <u data-lang="all">{{ $t("all") }}</u>
      </div>

    </div>
    <div>
      <v-card
        class="p-2 rounded-lg card-mission"
        :class="lineLayout == 'Compact' ? 'p-4' : ''"
        elevation="0"
        border
      >
        <template v-if="lineLayout != 'Compact'">
          <v-row
            no-gutters
            class="align-items-center"
            @click="previewReward(showReward)"
          >
            <v-col cols="2" class="px-1">
              <c-img
                class="reward-image"
                :src="showReward.image_url || handleImageSrc"
              ></c-img>
            </v-col>
            <v-col>
              <div class="d-inline-flex w-100 align-items-center">
                <div
                  class="line-clamp-2 f-sm break-all f-bold"
                  :style="'flex:1 1 50%'"
                >
                  {{ showReward.product_name }}
                </div>
                <div class="f-sm">
                  <div class="color-gray" data-lang="need_point">
                    {{ $t("need_point") }}
                  </div>
                  <div class="color-primary text-right">
                    {{
                      showReward.point_used - point >= 0
                        ? numeralFormat(showReward.point_used - point)
                        : 0
                    }}
                    <span data-lang="currency_unit">{{
                      $t("currency_unit")
                    }}</span>
                  </div>
                </div>
              </div>

              <v-progress-linear
                :model-value="point"
                :max="showReward.point_used"
                class="my-1 primary-color"
              ></v-progress-linear>
              <div class="content-between f-sm color-gray">
                <div data-lang="point_total">{{ $t("point_total") }}</div>
                <div>
                  {{
                    point > showReward.point_used
                      ? numeralFormat(showReward.point_used)
                      : numeralFormat(point)
                  }}/{{ numeralFormat(showReward.point_used) }}
                </div>
              </div>
            </v-col>

            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="mx-1"
              @click="previewReward(showReward)"
            />
          </v-row>
        </template>
        <template v-else>
          <div class="text-center mb-3">
            <span class="f-lg f-bold color-primary">
              {{
                point > totalRedeemPoint
                  ? numeralFormat(totalRedeemPoint)
                  : numeralFormat(point)
              }}/{{ numeralFormat(totalRedeemPoint) }}
            </span>
            <span class="color-gray f-md" data-lang="currency_unit">
              {{ $t("currency_unit") }}
            </span>
          </div>
          <div class="my-2 relative" id="reward-upcoming">
            <v-progress-linear
              color="custom-primary"
              :model-value="point"
              :max="totalRedeemPoint"
              height="5"
              rounded
            ></v-progress-linear>
            <div class="progress-dots" :style="`left:0;`"></div>
            <div class="absolute f-xs" :style="`left:-1.75%;top:10px`">
              Start
            </div>
            <template v-for="dots of rewardList" :key="dots.id">
              <div
                class="progress-dots"
                :class="dots.point_used <= point ? 'reach-redeem' : 'not-reach'"
                :style="`left:calc(${
                  (dots.point_used * 100) / totalRedeemPoint
                }% - 0.425em);transform: translateX(-${
                  (dots.point_used * 100) / totalRedeemPoint
                });`"
                @click="previewReward(dots)"
              ></div>

              <span
                class="absolute f-xs w-100"
                :style="`left:calc(${
                  (dots.point_used * 100) / totalRedeemPoint
                }% - 20px);transform: translateX(-${
                  (dots.point_used * 100) / totalRedeemPoint
                });top:10px;`"
                @click="previewReward(dots)"
              >
                {{ dots.product_name }}
              </span>
            </template>
          </div>
        </template>
      </v-card>
    </div>
    <v-dialog
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      @hidden="hide"
    >
      <div class="f-sm">
        <c-img :src="previewItems.image_url || handleImageSrc"></c-img>
        <div class="f-md f-bold">{{ previewItems.product_name }}</div>
        {{ numeralFormat(previewItems.point_used) }}
        <span data-lang="currency_unit">{{ $t("currency_unit") }}</span>
        <span v-html="previewItems.description"></span>
      </div>

      <c-btn block variant="confirm-primary" @click="hide()">
        <span data-lang="close">{{ $t("close") }}</span>
      </c-btn>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "UpcomingReward",

  data() {
    return {
      rewardList: [],
      point: this.$store.state.shortProfile.total_point,
      showReward: null,
      totalRedeemPoint: 0,
      previewItems: {},
      showModal: false
    };
  },
  computed: {
    title() {
      return this.$config.upcomming_text || "Upcoming Reward";
    },
    totalRedeemPoint() {
      return Math.max(...this.rewardList.map(o => o.point_used));
    }
  },
  mounted() {},
  created() {
    this.getReward();
  },
  methods: {
    async getReward() {
      const result = await this.$axios("/api/v1/user/GetRedeemReward");
      // this.rewardList = result.detail;
      this.rewardList = result.detail.filter(
        (value, index, self) =>
          index === self.findIndex(t => t.point_used === value.point_used)
      );
      this.initReward();
    },
    async initReward() {
      if (this.lineLayout == "Compact")
        return this.rewardList.length == 0
          ? (this.showReward = false)
          : (this.showReward = true);
      const reward = this.rewardList.find(el => el.point_used > this.point);

      this.showReward =
        this.rewardList.length > 0
          ? reward
            ? reward
            : this.rewardList[this.rewardList.length - 1]
          : null;
    },
    previewReward(value) {
      this.$router.push(
        `/reward/${value.id}?type=${value.product_type}&o_id=0`
      );
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style scoped lang="scss">
.mission-icon {
  /* background: red; */
  border-radius: 50%;
  /* padding: 1rem; */
  width: 35px;
  height: 35px;
  /* overflow: hidden; */
  /* object-fit: contain; */
  margin-right: 5px;
}
.primary-color {
  color: var(--primary-color);
  border-radius: 4px;
}
.reward-image {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
}
.progress-dots {
  position: absolute;
  border-radius: 50%;
  background-color: var(--primary-color);
  width: 0.75em;
  height: 0.75em;
  top: -0.2em;
}
#reward-upcoming .progress-dots ~ .progress-dots::after {
  content: "i";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  font-size: 0.5em;
  color: var(--font-primary-color);
}
.reach-redeem {
  opacity: 1;
}
.not-reach {
  opacity: 0.5;
}
</style>
