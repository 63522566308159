<template>
  <div>
    <v-dialog
      fullscreen
      v-model="showModal"
      transition="dialog-bottom-transition"
    >
      <!--  -->
      <div class="header-dialog">
        {{ changeTitle() }}
        <c-btn type="icon" :flat="true" @click="showModal = false" size="sm">
          <font-awesome-icon icon="times"
        /></c-btn>
      </div>
      <div class="content-policy p-4">
        <Policy v-if="type == 'policy'" />
        <Term v-else-if="type == 'term'" :brand="brand" :isPdf="isPdf" />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Policy from "@/views/page/policy";
import Term from "@/views/page/terms";
export default {
  components: { Policy, Term },
  data() {
    return { showModal: false, type: "", brand: "", isPdf: false };
  },
  mounted() {
    this.emitter.on("showModal", (type, brand, isPdf) => {
      this.brand = brand;
      this.isPdf = isPdf;
      this.showModal = true;
      this.type = type;
    });
    this.emitter.on("hideModal", () => {
      this.showModal = false;
      this.type = "";
      this.brand = "";
      this.isPdf = false;
    });
  },
  methods: {
    changeTitle() {
      return this.type == "policy"
        ? "นโยบายคุ้มครองข้อมูลส่วนบุคคล"
        : "ข้อกำหนดเงื่อนไข";
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal {
  .modal-fullscreen {
    .modal-content {
      .modal-header {
        position: sticky;
        top: 0;
        z-index: 1000;
        background: white;
      }
      border-radius: unset !important;
    }
    width: 100% !important;
    max-width: unset !important;
    margin: 0 !important;
  }
  padding-left: unset !important;
}
</style>
