<template>
  <div
    class="radio-container"
    :class="[
      {
        error: eachValid != null ? eachValid.$error && isValidate : isValidate
      }
    ]"
  >
    <label
      class="f-sm"
      :class="[
        {
          error: eachValid != null ? eachValid.$error && isValidate : isValidate
        }
      ]"
    >
      {{ labelName || title }}
      <!-- <span v-if="isRequired">*</span> -->
    </label>
    <!-- :error="isValidate" -->

    <v-radio-group
      density="compact"
      v-model="val"
      :value="value"
      @update:modelValue="
        $emit('change', $event), $emit('update:value', $event)
      "
      hide-details
      :name="name"
    >
      <template v-for="items of options" :key="items['valueField']">
        <v-radio
          :label="items[textField]"
          :value="items[valueField]"
          style="--v-icon-size: 12px; --v-selection-control-size: 12px"
        ></v-radio>
      </template>
    </v-radio-group>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      required: true,
      default: []
    },
    valueField: {
      required: false,
      default: "value"
    },
    textField: {
      required: false,
      default: "text"
    },
    value: {
      required: true
    },
    v: {
      required: false,
      default: () => {
        return {};
      }
    },
    validateText: {
      required: false
    },
    isValidate: {
      required: false
    },
    eachValid: {
      required: false,
      default: null
    },
    inline: { required: false, type: Boolean, default: true },
    name: { required: false },
    isRequired: { required: false, type: Boolean, default: false },
    title: { required: false },
    isNotValidate: { required: false }
  },

  computed: {
    val() {
      return this.value;
    },
    labelName() {
      if (this.v) {
        if (this.v.$error) {
          if (this.isNotValidate) return this.title;
          else return this.title + " " + this.getValidationError() + " *";
        } else if (this.isRequired) return this.title + " *";
      } else return this.title;
    }
  },
  created() {
    if (this.value) {
      let value = this.options.find(el => el.name == this.value);
      if (!value && this.options[0].field_id == 29) {
        this.$emit("update:value", "สาขา");
      }
    }
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      if (this.value) return;
      this.$nextTick(() => {
        let check = this.options.find(el => el.isDefault == 1);

        if (check) {
          this.$emit("update:value", check.name);
        }
      });
    },
    getValidationError(v = this.v, validateText = this.validateText, $t) {
      if (!v || !v.$error || (this.eachValid && !this.eachValid.$error))
        return "";

      const errorMessages = [
        {
          condition: (v.required && v.required.$invalid) || !v.required,
          message: validateText || this.$t("default_input_validate")
        },
        {
          condition: v.minLength && v.minLength.$invalid,
          message: `${this.$t("validate_input_min_length")} ${
            this.eachValid
              ? this.eachValid.$each.$response.$errors[this.index].value[0]
                  .$params.min
              : v.minLength && v.minLength.$params.min
          } ${this.$t("character")}`
        },
        {
          condition: v.email && v.email.$invalid,
          message: this.$t("validate_email")
        },
        {
          condition:
            (v.integer && v.integer.$invalid) ||
            (v.decimal && v.decimal.$invalid) ||
            (v.minValue && v.minValue.$invalid),
          message: this.$t("validate_input_number")
        },
        {
          condition: v.maxLength && v.maxLength.$invalid,
          message: `${this.$t("validate_input_max_length")} ${
            this.eachValid
              ? this.eachValid.$each.$response.$errors[this.index].value[0]
                  .$params.max
              : v.maxLength && v.maxLength.$params.max
          } ${this.$t("character")}`
        },
        {
          condition: v.alpha && v.alpha.$invalid,
          message: this.$t("validate_english_only")
        },
        {
          condition: v.alphaNumEng && v.alphaNumEng.$invalid,
          message: this.$t("validate_english_num_only")
        },
        {
          condition: v.Script_checkID && v.Script_checkID.$invalid,
          message: this.$t("validate_id_card")
        }
      ];

      const error = errorMessages.find(e => e.condition);
      console.log(error);
      return error ? error.message : "";
    }
  }
};
</script>

<style lang="scss">
.radio-container {
  border: 1px solid black;
  padding: 0.5rem 0.75rem;
}
.radio-container.error {
  border-color: red;
}
.v-radio .v-selection-control__input {
  transform: scale(0.5); /* ลดขนาดปุ่ม */
}
.radio-container label {
  font-weight: normal;
  &.error {
    color: red;
  }
}
</style>
