<template>
  <div class="px-2 pb-2" v-if="missionList.length >= 1">
    <div class="content-between mb-2">
      <div class="f-lg color-primary f-bold">{{ title }}</div>

      <router-link to="/mission" class="color-primary f-sm">
        <u data-lang="all">{{ $t("all") }}</u>
      </router-link>
    </div>
    <template v-if="missionList.length < 2">
      <v-carousel
        :show-arrows="false"
        height="auto"
        :cycle="missionList.length > 1"
        continuous
        :hide-delimiters="missionList.length <= 2"
      >
        <template v-for="(items, index) of missionList">
          <v-carousel-item v-if="(index + 1) % 2 === 1" :key="index">
            <v-row class="flex-nowrap col-gap-md" no-gutters>
              <template v-for="(n, i) in 2">
                <template v-if="+index + i < missionList.length">
                  <v-col :key="i">
                    <v-card
                      class="p-2 rounded-lg card-mission"
                      elevation="0"
                      border
                      :class="
                        index != 0 && missionList.length > 1 ? 'pl-2' : ''
                      "
                      :key="'mission-' + missionList[+index + i].id"
                      @click="
                        $router.push('/mission/2/' + missionList[+index + i].id)
                      "
                    >
                      <v-row v-if="missionList.length == 1">
                        <v-col cols="3" class="d-flex justify-content-center">
                          <div
                            :class="`progress-circle-lg mission-progress-${
                              +index + i
                            }`"
                            id="circle-progress"
                          >
                            <c-img
                              class="mission-icon-lg"
                              :src="missionList[+index + i].image_url"
                            ></c-img>
                          </div>
                        </v-col>
                        <v-col cols="9" class="flex-column d-flex">
                          <div
                            class="line-clamp-2 f-sm break-all mt-2"
                            :style="'flex:1'"
                          >
                            {{ missionList[+index + i].name }}
                          </div>
                          <div class="content-between f-xs">
                            <div>
                              {{ textType(items) }}
                            </div>
                            <div class="color-primary f-bold">
                              {{
                                missionList[+index + i].user_spender >=
                                missionList[+index + i].user_goal
                                  ? numeralFormat(
                                      missionList[+index + i].user_goal
                                    )
                                  : numeralFormat(
                                      missionList[+index + i].user_spender
                                    )
                              }}<span
                                v-if="
                                  missionList[+index + i]
                                    .is_show_only_have_stamp == 0
                                "
                                >/{{
                                  numeralFormat(
                                    missionList[+index + i].user_goal
                                  )
                                }}</span
                              >
                            </div>
                          </div>
                          <div
                            class="content-between f-xs profile-ft-color mt-auto"
                          >
                            <div>{{ $t("expired") }}</div>
                            <div>
                              {{
                                $moment(
                                  missionList[+index + i].expired_date
                                ).format("DD MMM YYYY")
                              }}
                            </div>
                          </div></v-col
                        >
                      </v-row>
                      <template v-else
                        ><div class="d-inline-flex align-items-center mb-1">
                          <div
                            :class="`progress-circle mission-progress-${
                              +index + i
                            }`"
                            id="circle-progress"
                          >
                            <c-img
                              class="mission-icon"
                              :src="missionList[+index + i].image_url"
                            ></c-img>
                          </div>

                          <div
                            class="line-clamp-2 f-sm break-all"
                            :style="'flex:1'"
                          >
                            {{ missionList[+index + i].name }}
                          </div>
                        </div>
                        <div class="content-between f-xs">
                          <div>
                            {{ textType(items) }}
                          </div>
                          <div class="color-primary f-bold">
                            {{
                              missionList[+index + i].user_spender >=
                              missionList[+index + i].user_goal
                                ? numeralFormat(
                                    missionList[+index + i].user_goal
                                  )
                                : numeralFormat(
                                    missionList[+index + i].user_spender
                                  )
                            }}<span
                              v-if="
                                missionList[+index + i]
                                  .is_show_only_have_stamp == 0
                              "
                              >/{{
                                numeralFormat(missionList[+index + i].user_goal)
                              }}
                            </span>
                          </div>
                        </div>
                        <div class="content-between f-xs profile-ft-color">
                          <div>{{ $t("expired") }}</div>
                          <div>
                            {{
                              $moment(
                                missionList[+index + i].expired_date
                              ).format("DD MMM YYYY")
                            }}
                          </div>
                        </div></template
                      >
                    </v-card>
                  </v-col>
                </template>
              </template>
            </v-row>
          </v-carousel-item>
        </template>
      </v-carousel>
    </template>

    <template v-else>
      <carousel
        :wrapAround="true"
        :transition="500"
        :items-to-show="2"
        :autoplay="3000"
        snapAlign="start"
      >
        <slide v-for="(items, index) of missionList" :key="items" class="pl-2">
          <v-card
            class="p-2 rounded-lg card-mission"
            elevation="0"
            border
            :key="'mission-' + items.id"
            @click="$router.push('/mission/2/' + items.id)"
          >
            <div class="d-inline-flex align-items-center mb-1">
              <div
                :class="`progress-circle mission-progress-${+index}`"
                id="circle-progress"
              >
                <c-img class="mission-icon" :src="items.image_url"></c-img>
              </div>

              <div class="line-clamp-2 f-sm break-all" :style="'flex:1'">
                {{ items.name }}
              </div>
            </div>
            <div class="content-between f-xs">
              <div>
                {{ textType(items) }}
              </div>
              <div class="color-primary f-bold">
                {{
                  items.user_spender >= items.user_goal
                    ? numeralFormat(items.user_goal)
                    : numeralFormat(items.user_spender)
                }}/{{ numeralFormat(items.user_goal) }}
              </div>
            </div>
            <div class="content-between f-xs profile-ft-color">
              <div>หมดอายุ</div>
              <div>
                {{ $moment(items.expired_date).format("DD MMM YYYY") }}
              </div>
            </div>
          </v-card>
        </slide>

        <template #addons>
          <!-- <navigation /> -->
          <pagination />
        </template>
      </carousel>
    </template>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  name: "MissionComponent",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data() {
    return {
      missionList: []
    };
  },
  computed: {
    title() {
      return this.$t("mission_title") || "มิชชั่นของคุณ";
    }
  },
  created() {
    this.getMissionList();
  },
  methods: {
    async getMissionList() {
      const result = await this.$axios("/api/v1/user/GetMissionList");
      this.missionList = result.detail;
      this.initProgress();
    },
    createProgress(index, value) {
      var css = ``,
        head = document.head || document.getElementsByTagName("head")[0],
        style = document.createElement("style");
      css = `
      @property --progress-value-${index} {
        syntax: "<integer>";
        initial-value: 0;
        inherits: false;
      }
      @keyframes js-progress-${index} {
        to {
          --progress-value-${index}: ${value};
        }
      }
      .mission-progress-${index} {
        background: radial-gradient(closest-side, white 79%, transparent 80% 100%, white 0), conic-gradient(var(--secondary-color) calc(var(--progress-value-${index}) * 1%), var(--secondary-color-light) 0);
        animation: js-progress-${index} 1s 1 forwards;
      }
      `;
      head.appendChild(style);

      style.type = "text/css";
      if (style.styleSheet) {
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    },
    initProgress() {
      var i = 0;
      for (const items of this.missionList) {
        // if ((i + 1) % 2 === 1) {
        //   for (let n = 0; n < 2; n++) {
        //     if (+i + n < this.missionList.length) {
        let value = Math.round(
          (this.missionList[i].user_spender * 100) /
            this.missionList[i].user_goal
        );

        this.createProgress(i, value);
        // }
        // }
        i++;
      }
    },
    formatNumber(num, precision = 0) {
      const map = [
        { suffix: "M", threshold: 1e6, multiple: 1 },
        { suffix: "K", threshold: 1e4, multiple: 10 },
        { suffix: "K", threshold: 1e3, multiple: 1 },
        { suffix: "", threshold: 1, multiple: 1 }
      ];

      const found = map.find(x => Math.abs(num) >= x.threshold);

      if (found) {
        const formatted =
          (num / found.threshold) * found.multiple + found.suffix;

        return formatted;
      }

      return num;
    },
    textType(items) {
      if (items.mission_redeem_type == 1) return this.$t("total_spend_unit");
      else if (items.mission_redeem_type == 2) {
        if (items.mission_redeem_condition == 1)
          return this.$t("count_purchase_times");
        else return this.$t("count_purchase_unit");
      } else if (items.mission_redeem_type == 3)
        return this.$t("collect_point");
    }
  }
};
</script>

<style scoped lang="scss">
/* @import "@/assets/stylesheets/customVueSlick.css"; */
::v-deep .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0;
}
::v-deep .slick-dots {
  bottom: -15px;
}
::v-deep .slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  content: "•";
}
::v-deep .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 10px;
  height: 10px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
::v-deep .slick-next {
  right: -7px;
}
::v-deep .slick-prev {
  left: -12px;
}
::v-deep .slick-prev,
::v-deep .slick-next {
  width: unset;
  height: unset;
}
::v-deep .slick-next:before {
  content: "\203A";
}
::v-deep .slick-prev:before {
  content: "\2039";
}
::v-deep .slick-prev:before,
::v-deep .slick-next:before {
  font-family: unset;
  color: black;
}
::v-deep .slick-prev.slick-disabled:before,
::v-deep .slick-next.slick-disabled:before {
  display: none;
}

.mission-icon {
  /* background: red; */
  border-radius: 50%;
  /* padding: 1rem; */
  width: 35px;
  height: 35px;
  /* overflow: hidden; */
  /* object-fit: contain; */
  margin-right: 5px;
}

.break-all {
  word-break: break-all;
}
.card-mission {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
#circle-progress {
  .mission-icon {
    position: absolute;
    top: 3px;
    left: 3px;
    overflow: hidden;
  }
  .mission-icon-lg {
    position: absolute;
    top: 5px;
    left: 5px;
    overflow: hidden;
    /* background: red; */
    border-radius: 50%;
    /* padding: 1rem; */
    width: 50px;
    height: 50px;
    /* overflow: hidden; */
    /* object-fit: contain; */
    // margin-right: 5px;
  }
}
.progress-circle {
  position: relative;
  width: 41px;
  height: 41px;
  justify-content: center;
  border-radius: 50%;
  margin-right: 5px;
}
.progress-circle-lg {
  position: relative;
  width: 60px;
  height: 60px;
  justify-content: center;
  border-radius: 50%;
  // margin-right: 5px;
}
</style>
