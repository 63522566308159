import axios from "axios";
import store from "../store";
import { action as ActionList } from "@/assets/json/logger";
import moment from "moment";
import BrandKey from "@/assets/json/logbrand";
const logger = ({ response, action }) => {
  try {
    let location = window.location.hostname;
    let brand = location.split("-")[0];
    let config = response.config;

    let start;
    let duration;
    let path = window.location.pathname.replace("/", " ").trim();
    if (config.metadata) {
      config.metadata.endTime = new Date();
      start = moment(config.metadata.startTime).utcOffset(7);
     
      let end = moment(config.metadata.endTime);
      duration = moment
        .utc(moment(end).diff(moment(start)))
        .format("HH:mm:ss.SSS");
    }

    if (!action) {
      action = ActionList.find(
        el =>
          config.url.includes(el.url) &&
          (el.path == "" || el.path == window.location.pathname)
      );
    }
    if (
      (config.url == "/api/v1/user/LoginExternal" ||
        config.url == "/api/v1/user/CheckSocialBind") &&
      response.status == 200
    )
      return;
    let responseBody =
      response.status == 200 || response.status < 500
        ? JSON.stringify(response.data)
        : "";
    let actionName = action ? action.action : "";
    let actionId = action ? action.id : 2;
    let payload = {
      LogLevelId: actionId,
      Menu: path,
      Action: actionName,
      DeviceOS: window.navigator.platform,
      DeviceType: window.navigator.userAgent,
      UserGUID: store.state.shortProfile.user_guid || "",
      Method: config.method,
      Url: config.url,
      ResponseCode: response.status,
      Body: config.data,
      ResponseBody: responseBody,
      RequestTime: start.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      ResponseTime: duration ? duration.toString() : "0",
      Note: action ? action.note : ""
    };
   
    const key = BrandKey.find(el => el.brand.toLowerCase() == brand);
    axios.post(`${process.env.VUE_APP_LOGGER_API}/Log`, payload, {
      headers: {
        "api-key": key ? key.key : "Cmd9Fk3QosXlakF0gMkeQ9fASogF"
      }
    });
  } catch (error) {
    console.error("logger error:", error);
  }
};

export { logger };
