<template>
  <div>
    <div class="content-between my-3">
      <div class="f-xl f-bold">{{ $t("news") }}</div>
      <router-link to="/news" class="text-gray f-12 text-underline">
        <u>{{ $t("all") }}</u>
      </router-link>
    </div>
    <carousel
      :wrapAround="true"
      :transition="500"
      :items-to-show="2.1"
      :autoplay="3000"
      snapAlign="start"
    >
      <slide v-for="items of newsList" :key="items" class="p-2">
        <v-card
          no-body
          class="border-none shadow-sm news-card"
          v-on:click="getForm(items)"
        >
          <video
            class="img-fluid w-100 d-block aspect-16-9"
            muted
            autoplay
            controls
            playsinline
            :ref="'video-' + items.id"
            v-if="items.is_video == 1"
          >
            <source :src="items.image_url" type="video/mp4" />
          </video>
          <img
            v-else
            class="d-block img-fluid w-100"
            :src="items.image_url"
            alt="image slot"
          />
          <div class="p-2">
            <div class="line-clamp-1 f-bold">{{ items.name }}</div>
            <div class="line-clamp-2 text-gray f-md">
              {{ items.short_description }}
            </div>
          </div>
        </v-card>
      </slide>

      <template #addons>
        <pagination />
      </template>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  data() {
    return {
      isLoading: false,
      newsList: [],
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      },

      slick: 0,
      interval: 3000,
      filterNews: {
        page: 1,
        take: 10,
        id: []
      },
      settings: {
        dot: true,
        arrow: false,
        centerMode: true,
        centerPadding: "5px",
        focusOnSelect: true,
        infinite: true,
        autoplay: true,
        slidesToShow: 2,
        speed: 2000
      }
    };
  },
  created() {
    this.getNewsList();
  },
  methods: {
    async getNewsList() {
      await this.$axios
        .post(`/api/v1/MonthlyPrivilege/list`, this.filterNews)
        .then(async data => {
          // this.isLoading = false;
          if (data.result == 1) {
            this.newsList = data.detail.detail;
            this.isLoading = false;
          }
        });
    },
    getForm(item, index) {
      this.$router.push("/news/" + item.id);
    }
  }
};
</script>

<style>
.news-card {
  height: 100%;
  max-height: 250px;
  overflow: hidden;
  text-align: left;
  border-radius: 0.5rem;
}
:root {
  --vc-pgn-width: 8px;
  --vc-pgn-height: 8px;
  --vc-pgn-border-radius: 50%;
  --vc-pgn-active-color: var(--primary-color);
}
.carousel__pagination-button::after {
  /* background-color: var(--primary-color); */
  background-color: transparent;
  border: 1px solid var(--primary-color);
  opacity: 0.5;
}
.carousel__pagination-button--active::after {
  background-color: var(--primary-color);
  opacity: 1;
}
li.carousel__pagination-item {
  width: 12px;
  height: 12px;
}
</style>
