<template>
  <div class="reletive" v-if="lineLayout == 'Tier (Full Display)'">
    <div class="range-avg start" id="range-start" :data-avg="0"></div>

    <v-progress-linear
      rounded
      :color="barColor()"
      v-if="
        profile.expired_date ||
        memberLevel.upgrade_tier_id != 0 ||
        memberLevel.maintain_baht != 0
      "
      class="mb-1"
      height="20px"
      :model-value="profile.total_spend"
      :max="maxValueProgress"
      style="border: 1px solid #80808059"
    >
    </v-progress-linear>

    <div
      class="range-avg maintain"
      id="range-maintain"
      :data-avg="memberLevel.maintain_baht"
      v-if="
        memberLevel.maintain_baht != 0 && memberLevel.downgrade_tier_id != 0
      "
    ></div>

    <template v-for="level of memberList" :key="level.name">
      <div
        class="range-avg upgrade"
        :id="'range-upgrade-' + level.name"
        :data-avg="level.upgrade_baht"
        v-if="
          level.upgrade_tier_id != 0 &&
          level.upgrade_tier_id != $store.state.shortProfile.member_level_id
        "
      ></div
    ></template>
  </div>
  <div class="reletive" v-else>
    <div class="range-avg start" id="range-start" :data-avg="0"></div>
    <v-progress-linear
      rounded
      :color="barColor()"
      v-if="
        profile.expired_date ||
        memberLevel.upgrade_tier_id != 0 ||
        memberLevel.maintain_baht != 0
      "
      class="mb-1"
      height="20px"
      :model-value="profile.total_spend"
      :max="maxValueProgress"
      style="border: 1px solid #80808059"
    >
    </v-progress-linear>

    <div
      class="range-avg maintain"
      id="range-maintain"
      :data-avg="memberLevel.maintain_baht"
      v-if="memberLevel.maintain_baht > 0"
    ></div>

    <div
      class="range-avg upgrade"
      id="range-upgrade"
      :data-avg="memberLevel.upgrade_baht"
      v-if="memberLevel.upgrade_baht > 0"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    memberLevel: {
      required: true
    }
  },
  data() {
    return {
      profile: this.$store.state.shortProfile,
      memberList: this.$store.state.shortProfile.member_list
    };
  },

  computed: {
    progressValue() {
      return this.numeralFormat(this.profile.total_point);
    },
    maxValueProgress() {
      let memberList = this.$store.state.shortProfile.member_list;

      if (this.lineLayout == "Tier (Full Display)")
        return this.memberLevel.upgrade_baht == 0
          ? this.memberLevel.maintain_baht
          : memberList[memberList.length - 2].upgrade_baht;
      else
        return this.memberLevel.upgrade_baht == 0
          ? this.memberLevel.maintain_baht
          : this.memberLevel.upgrade_baht;
    }
  },
  created() {
    function getUpgradeTiers(tiers, currentTier) {
      const result = [];
      const visited = new Set(); // Set เพื่อเก็บ ID ที่เคยเจอแล้ว

      let tier = tiers.find(t => t.id === currentTier); // เริ่มที่ Tier ปัจจุบัน
      // visited.add(currentTier);
      console.log("Starting Tier:", tier, currentTier);
      // if (tier.id == currentTier) return;
      if (!tier) {
        console.warn(`No tier found for currentTier ID: ${currentTier}`);
        return []; // ถ้าไม่เจอ tier ที่ตรง, ให้คืนค่าเป็น []
      }

      while (tier) {
        if (visited.has(tier.id)) {
          console.warn(
            `Cycle detected at Tier ID: ${tier.id}, stopping to prevent infinite loop.`
          );
          break;
        }

        visited.add(tier.id); // บันทึก ID ที่เคยเจอ
        result.push(tier); // เพิ่ม Tier ลงในผลลัพธ์

        // หยุดถ้า upgrade_tier_id เป็น 0 หรือ อ้างอิงตัวเอง
        if (tier.upgrade_tier_id === 0 || tier.upgrade_tier_id === tier.id) {
          break;
        }

        tier = tiers.find(t => t.id === tier.upgrade_tier_id);
        console.log("Next Tier:", tier);
        if (!tier) {
          break;
        }
      }

      return result;
    }

    // เรียกใช้ฟังก์ชัน พร้อมตรวจสอบค่า input
    console.log("Member List:", this.memberList);
    console.log("Member Level ID:", this.memberLevel?.id);

    this.memberList = getUpgradeTiers(this.memberList, this.memberLevel?.id);

    this.$nextTick();
  },
  mounted() {
    const rangeMaintain = document.getElementById("range-maintain");
    var max = this.maxValueProgress;
    if (rangeMaintain) {
      const avgValue = rangeMaintain.dataset.avg;

      let avgPosition = Number((avgValue * 100) / max);
      avgPosition =
        avgPosition < 1
          ? "13px"
          : `calc(${avgPosition >= 100 ? 98 : avgPosition}% - 13px)`;
      rangeMaintain.style.left = avgPosition;
      this.$nextTick();
    }
    if (this.lineLayout == "Tier (Full Display)") {
      for (const level of this.$store.state.shortProfile.member_list) {
        const pointUpgrade = document.getElementById(
          "range-upgrade-" + level.name
        );

        if (pointUpgrade) {
          const avgValue = pointUpgrade.dataset.avg;

          let avgPosition = Number((avgValue * 100) / max);

          pointUpgrade.style.left = `calc(${
            avgPosition >= 100 ? 98 : avgPosition
          }% - 13px)`;
        }
      }
    } else {
      const pointUpgrade = document.getElementById("range-upgrade");
      if (pointUpgrade) {
        const avgValue = pointUpgrade.dataset.avg;

        let avgPosition = Number((avgValue * 100) / max);

        pointUpgrade.style.left = `calc(${
          avgPosition >= 100 ? 98 : avgPosition
        }% - 13px)`;
      }
    }
  },
  methods: {
    barColor() {
      if (this.memberLevel.upgrade_baht == 0) return "maintain-color";
      if (this.progressValue <= this.memberLevel.maintain_baht)
        return "maintain-color";
      return this.progressValue <= this.maxValueProgress
        ? "maintain-color"
        : "upgrade-color";
    }
  }
};
</script>

<style lang="scss">
.bg-start-color {
  background: rgb(219, 219, 219);
}
.bg-upgrade-color {
  background: rgb(42, 190, 168);
}
.bg-maintain-color {
  background: rgb(250, 171, 50);
}

.range-avg {
  z-index: 1;
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  height: 13px;
  width: 13px;

  z-index: 1;
  appearance: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform: translate(0, -50%);
  border: 3px solid white;
  &.maintain {
    background: rgb(250, 171, 50);
  }
  &.upgrade {
    background: rgb(42, 190, 168);
  }
  &.start {
    background: rgb(219, 219, 219);
    left: 1.5%;
  }
}
</style>
