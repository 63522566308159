<template>
  <MemberProgress :memberLevel="memberLevel" v-if="memberLevel" />
  <div
    class="d-inline-flex w-100 f-sm"
    v-if="
      profile.expired_date ||
      (memberLevel.upgrade_tier_id != 0 && memberLevel.upgrade_baht != 0) ||
      (memberLevel.downgrade_tier_id != 0 && memberLevel.maintain_baht != 0)
    "
  >
    <template v-if="lineLayout == 'Tier (Full Display)'">
      <span
        :data-lang="
          memberLevel.upgrade_tier_id ? 'total_spend' : 'keep_current'
        "
      >
        <template v-if="memberLevel.upgrade_tier_id">
          <span data-lang="total_spend"> {{ $t("total_spend") }}&nbsp;</span>
        </template>
        <template v-else>
          <span data-lang="keep_current"> {{ $t("keep_current") }}&nbsp;</span>
        </template>
      </span>
      <div class="ml-auto">
        <span class="highlight-color f-bold">
          {{ numeralFormat(profile.total_spend) }}
        </span>
        <span data-lang="currency_unit">&nbsp;{{ $t("upgrade_unit") }} </span>
      </div>
    </template>
    <template v-else>
      <span data-lang="more_spending"> {{ $t("more_spending") }} </span>
      <span
        :data-lang="memberLevel.upgrade_tier_id ? 'to_upgrade' : 'keep_current'"
      >
        <template v-if="memberLevel.upgrade_tier_id">
          <span data-lang="to_upgrade"> {{ $t("to_upgrade") }}&nbsp;</span>
          <span class="f-bold">
            {{ memberLevel.upgrade_tier_name || $t("next_tier") }}
          </span>
        </template>
        <template v-else>
          <span data-lang="keep_current"> {{ $t("keep_current") }}&nbsp;</span>
          <span class="f-bold">
            {{ memberLevel.member_level_language[0].display }}
          </span>
        </template>
      </span>
      <div class="ml-auto">
        <span class="highlight-color f-bold">
          {{ numeralFormat(profile.total_spend) }}/{{
            numeralFormat(
              memberLevel.upgrade_tier_id != 0
                ? memberLevel.upgrade_baht
                : memberLevel.maintain_baht
            )
          }}
        </span>
        <span data-lang="currency_unit">&nbsp;{{ $t("upgrade_unit") }} </span>
      </div>
    </template>
  </div>

  <div class="d-inline-flex w-100 f-sm" v-if="profile.expired_date">
    <div data-lang="member_tier_expired">
      {{ $t("member_tier_expired") }}
    </div>
    <div class="ml-auto f-bold text-danger">
      {{ $moment(profile.expired_date).format("DD MMM YYYY") }}

      <v-tooltip open-on-click>
        <template v-slot:activator="{ props }">
          <font-awesome-icon
            v-bind="props"
            id="tooltip-info"
            :icon="['fas', 'info-circle']"
            class="f-sm ml-1"
          />
        </template>
        <template v-slot:default>
          <template v-if="lineLayout == 'Tier (Full Display)'">
            <template
              v-for="level of memberList"
              :key="'tooltip-' + level.name"
            >
              <div class="content-between" v-if="level.upgrade_baht">
                <div>
                  <span :data-lang="'to_upgrade'">
                    <span class="f-bold">
                      {{
                        getDisplayName(level.upgrade_tier_id) ||
                        $t("next_tier")
                      }}&nbsp;
                    </span>
                    <span data-lang="to_upgrade">
                      {{ $t("to_upgrade") }}&nbsp;
                    </span>
                  </span>
                </div>
                <div class="text-danger f-bold">
                  {{ numeralFormat(level.upgrade_baht) }}
                  <span data-lang="upgrade_unit">
                    {{ $t("upgrade_unit") }}
                  </span>
                </div>
              </div>
            </template>

            <div
              class="content-between"
              v-if="memberLevel.upgrade_tier_id == 0"
            >
              <div>
                <span :data-lang="'maintain'">
                  <span data-lang="keep_current">
                    {{ $t("keep_current") }}&nbsp;
                  </span>
                  <span class="f-bold">
                    {{ memberLevel.member_level_language[0].display }}&nbsp;
                  </span>
                </span>
              </div>
              <div class="text-danger f-bold">
                {{ numeralFormat(memberLevel.maintain_baht) }}
                <span data-lang="upgrade_unit">
                  {{ $t("upgrade_unit") }}
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="content-between" v-if="memberLevel.upgrade_tier_id">
              <div>
                <span data-lang="more_spending">
                  {{ $t("more_spending") }}
                </span>
                <span :data-lang="'to_upgrade'">
                  <span data-lang="to_upgrade">
                    {{ $t("to_upgrade") }}&nbsp;
                  </span>
                  <span class="f-bold">
                    {{ memberLevel.upgrade_tier_name || $t("next_tier") }}&nbsp;
                  </span>
                </span>
              </div>
              <div class="text-danger f-bold">
                {{ remainValue("upgrade_baht") }}
                <span data-lang="upgrade_unit">
                  {{ $t("upgrade_unit") }}
                </span>
              </div>
            </div>
            <div
              class="content-between"
              v-if="remainValue('maintain_baht') != -1"
            >
              <div>
                <span data-lang="more_spending">
                  {{ $t("more_spending") }}
                </span>
                <span :data-lang="'maintain'">
                  <span data-lang="keep_current">
                    {{ $t("keep_current") }}&nbsp;
                  </span>
                  <span class="f-bold">
                    {{ memberLevel.member_level_language[0].display }}&nbsp;
                  </span>
                </span>
              </div>
              <div class="text-danger f-bold">
                {{ remainValue("maintain_baht") }}
                <span data-lang="upgrade_unit">
                  {{ $t("upgrade_unit") }}
                </span>
              </div>
            </div>
          </template>
          <div class="content-between">
            <div data-lang="member_tier_expired">
              {{ $t("member_tier_expired") }}&nbsp;
            </div>
            <div class="text-danger f-bold">
              {{ $moment(profile.expired_date).format("DD MMM YYYY") }}
            </div>
          </div>
        </template>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import MemberProgress from "@/components/MemberProgress";

export default {
  components: { MemberProgress },
  props: {
    profile: {
      required: true,
      type: Object
    },

    memberLevel: {
      required: false
    }
  },
  data() {
    return {
      memberList: this.$store.state.shortProfile.member_list
    };
  },
  created() {
    function getUpgradeTiers(tiers, currentTier) {
      const result = [];
      const visited = new Set(); // Set เพื่อเก็บ ID ที่เคยเจอแล้ว

      let tier = tiers.find(t => t.id === currentTier); // เริ่มที่ Tier ปัจจุบัน

      while (tier) {
        if (visited.has(tier.id)) {
          console.warn(
            `Cycle detected at Tier ID: ${tier.id}, stopping to prevent infinite loop.`
          );
          break;
        }

        visited.add(tier.id); // บันทึก ID ที่เคยเจอ
        result.push(tier); // เพิ่ม Tier ลงในผลลัพธ์

        // หยุดถ้า upgrade_tier_id เป็น 0 หรือ อ้างอิงตัวเอง
        if (tier.upgrade_tier_id === 0 || tier.upgrade_tier_id === tier.id) {
          break;
        }

        tier = tiers.find(t => t.id === tier.upgrade_tier_id);
        if (!tier) {
          break;
        }
      }

      return result;
    }

    this.memberList = getUpgradeTiers(this.memberList, this.memberLevel.id);
  },
  mounted() {},
  methods: {
    remainValue(params) {
      let sum = this.memberLevel[params] - this.profile.total_spend;
      return sum <= 0 ? -1 : this.numeralFormat(sum);
    },
    getDisplayName(id) {
      if (id == 0) return;
      return this.$store.state.shortProfile.member_list.find(a => a.id == id)
        .member_level_language[0].display;
    }
  }
};
</script>

<style></style>
