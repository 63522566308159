<template>
  <div
    class="text-center h-100-vh d-flex align-items-center justify-content-center"
    :class="{ 'bg-white': !lineLayout.includes('Minimal') }"
  >
    <Spinner
      label="Loading..."
      :variant="variant"
      class="m-5 spinner-main"
    ></Spinner>
  </div>
</template>

<script>
export default {
  name: "loading",
  props: {
    variant: {
      required: false,
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.h-100-vh {
  height: calc(100vh - 80px);
}
</style>
