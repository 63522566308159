<template>
  <div>
    <div class="content-between px-3 pt-3">
      <div class="form-name">{{ tabsName }}</div>
      <div>
        <font-awesome-icon
          icon="bars"
          :style="{
            color: 'var(--secondary-color)',
            opacity: isCardView ? 1 : 0.5
          }"
          @click="isCardView = true"
          class="icon-view"
        />
        <font-awesome-icon
          icon="list"
          :style="{
            color: 'var(--secondary-color)',
            opacity: !isCardView ? 1 : 0.5
          }"
          @click="isCardView = false"
          class="icon-view"
        />
      </div>
    </div>
    <template v-if="isCardView">
      <div v-if="isLoading" class="p-3">
        <div class="card-loading empty-card-view shadow-sm"></div>
      </div>
      <template v-else>
        <template v-if="data.length > 0">
          <div class="horizontal-snap">
            <v-card
              no-body
              v-for="(a, n) of data"
              :key="a.answer_id"
              class="shadow-sm border-none"
              footer-class="mt-auto py-2"
            >
              <c-img
                :src="getImage(a)"
                class="form-image"
                alt="form upload image"
                @error="handleImageSrc"
              ></c-img>
              <div class="p-3">
                <v-row class="row-gap-lg">
                  <template v-for="(field, index) of a.datas">
                    <v-col
                      cols="6"
                      :key="'field-name-' + field.answer_id + index"
                      v-if="index != 0"
                      class="field-name"
                    >
                      {{ field.field_name }}
                    </v-col>
                    <v-col
                      cols="6"
                      :key="'field-value-' + field.answer_id + index"
                      v-if="index != 0"
                      class="field-answer"
                    >
                      <div v-if="field.field_type_id == 7">
                        <template v-if="field.answer_detail[0].value_answer">
                          {{
                            $moment(field.answer_detail[0].value_answer).format(
                              "DD/MM/YYYY"
                            )
                          }}
                          <div>
                            ({{
                              countDate(field.answer_detail[0].value_answer)
                            }})
                          </div>
                        </template>
                      </div>
                      <div v-else-if="field.field_type_id == 5">
                        <template v-if="field.answer_detail[0].value_answer">
                          {{
                            $moment(field.answer_detail[0].value_answer).format(
                              "DD MMM YYYY (HH:mm)"
                            )
                          }}
                        </template>
                      </div>
                      <div v-else>
                        {{
                          field.answer_detail
                            .map(el => el.value_answer)
                            .join(",")
                        }}
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </div>

              <div
                class="d-flex align-items-center justify-content-between p-3"
              >
                <div>
                  <c-btn
                    size="x-small"
                    class="rounded-lg f-sm"
                    variant="confirm-primary"
                    @click="editForm(a.answer_id, n)"
                    >Edit</c-btn
                  >
                </div>
                <div>
                  <c-btn
                    size="lg"
                    variant="link"
                    v-if="a.allow_customer_delete == 1"
                  >
                    <font-awesome-icon
                      icon="trash-alt"
                      @click="deleteForm(a.answer_id, n)"
                      style="color: var(--primary-color)"
                  /></c-btn>
                </div>
              </div>
            </v-card>
            <div class="empty-card-view" @click="gotoForm()" v-if="!isFull">
              <div class="add-new-form">
                <div data-lang="add">{{ $t("add") }}</div>
                <div class="add-icon">
                  <font-awesome-icon icon="plus" class="icon-view" />
                </div>
              </div>
            </div>
          </div>
          <div class="pagination mb-3">
            <div
              v-for="(field, index) of data"
              :key="index"
              :class="[index + 1 == page ? 'active' : '', 'page']"
              @click="clickPage(index + 1)"
            ></div>
            <div
              :class="[data.length + 1 == page ? 'active' : '', 'page']"
              @click="clickPage(data.length + 1)"
            ></div>
          </div>
        </template>
        <template v-else>
          <div class="p-3">
            <div class="empty-card-view" @click="gotoForm()" v-if="!isFull">
              <div class="add-new-form">
                <div data-lang="add">{{ $t("add") }}</div>
                <div class="add-icon">
                  <font-awesome-icon icon="plus" class="icon-view" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>
    <template v-else>
      <div v-if="isLoading" class="p-3">
        <div class="card-loading empty-list-view shadow-sm"></div>
      </div>
      <template v-else>
        <div class="list-view-container" v-if="data.length > 0">
          <v-card
            no-body
            v-for="(a, n) of data"
            :key="a.answer_id"
            class="shadow-sm border-none .rounded-lg o-hidden"
            footer-class="mt-auto"
            no-footer
            hide-footer
          >
            <div>
              <v-row no-gutters>
                <template v-for="(field, index) of a.datas">
                  <v-col
                    cols="5"
                    :key="'field-name1-' + field.answer_id + index"
                    v-if="index == 0"
                  >
                    <c-img
                      :src="getImage(a)"
                      class="form-image-list"
                      alt="form upload image"
                      @error="handleImageSrc"
                    ></c-img
                  ></v-col>
                  <v-col
                    cols="7"
                    class="pt-3 px-3"
                    :key="'field-name2-' + field.answer_id + index"
                    v-if="index == 0"
                  >
                    <v-row class="row-gap-md">
                      <template
                        v-for="(field, n) of a.datas.filter(
                          el => el.field_type_id != 8
                        )"
                      >
                        <template v-if="n < 3">
                          <div
                            class="field-name col-6"
                            :key="'field-name-' + n + index"
                          >
                            {{ field.field_name }}
                          </div>
                          <div
                            v-if="field.field_type_id == 7"
                            class="field-answer line-clamp-1 col-6"
                            :key="'datetime-' + n + index"
                          >
                            <template
                              v-if="field.answer_detail[0].value_answer"
                            >
                              {{
                                $moment(
                                  field.answer_detail[0].value_answer
                                ).format("DD/MM/YYYY")
                              }}

                              ({{
                                countDate(field.answer_detail[0].value_answer)
                              }})
                            </template>
                          </div>
                          <div
                            v-else-if="field.field_type_id == 5"
                            class="field-answer line-clamp-1 col-6"
                            :key="'date-' + n + index"
                          >
                            <template
                              v-if="field.answer_detail[0].value_answer"
                            >
                              {{
                                $moment(
                                  field.answer_detail[0].value_answer
                                ).format("DD MMM YYYY (HH:mm)")
                              }}
                            </template>
                          </div>
                          <div
                            v-else
                            class="field-answer line-clamp-1 col-6"
                            :key="'normal-' + n + index"
                          >
                            {{
                              field.answer_detail
                                .map(el => el.value_answer)
                                .join(",")
                            }}
                          </div>
                        </template>
                      </template></v-row
                    >

                    <div
                      class="d-flex align-items-center py-3 justify-content-between"
                    >
                      <div>
                        <c-btn
                          size="x-small"
                          class="rounded-lg f-sm"
                          variant="confirm-primary"
                          @click="editForm(a.answer_id, n)"
                          >Edit</c-btn
                        >
                      </div>
                      <div>
                        <c-btn
                          size="lg"
                          variant="link"
                          v-if="a.allow_customer_delete == 1"
                        >
                          <font-awesome-icon
                            icon="trash-alt"
                            @click="deleteForm(a.answer_id, n)"
                            style="color: var(--primary-color)"
                        /></c-btn>
                      </div>
                    </div>
                  </v-col>
                </template>
              </v-row>
            </div>
            <!-- 
          <template #footer>
  
          </template> -->
          </v-card>
          <div
            class="empty-list-view shadow-sm"
            @click="gotoForm()"
            v-if="!isFull"
          >
            <div class="add-new-form">
              <div data-lang="add">{{ $t("add") }}</div>
              <div class="add-icon">
                <font-awesome-icon icon="plus" class="icon-view" />
              </div>
            </div>
          </div>
        </div>

        <div class="p-3" v-else>
          <div
            class="empty-list-view shadow-sm"
            @click="gotoForm()"
            v-if="!isFull"
          >
            <div class="add-new-form">
              <div data-lang="add">{{ $t("add") }}</div>
              <div class="add-icon">
                <font-awesome-icon icon="plus" class="icon-view" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    formId: {
      required: false
    },
    tabsName: {
      required: false,
      default: "Custom Object"
    },
    isFull: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      payload: {
        custom_object_id: this.formId || this.$route.params.id, // must have na !!!
        search: "",
        user_guid: "", // หน้า customer detail ใส่ user_guid มาด้วยนะ
        date_from: "",
        date_to: "",
        page: 1,
        page_size: 10
      },
      data: [],
      page: 1,
      isLoading: true,
      isCardView: true
    };
  },
  created() {
    this.getData();
  },
  mounted() {},
  updated: function () {
    var $this = this;

    if (this.data)
      this.$nextTick(function () {
        if (document.getElementsByClassName("horizontal-snap")[0]) {
          let width = document.getElementsByClassName("v-card")[0].offsetWidth;
          document
            .getElementsByClassName("horizontal-snap")[0]
            .addEventListener(
              "scroll",
              function () {
                let numb =
                  document.getElementsByClassName("horizontal-snap")[0]
                    .scrollLeft /
                    width +
                  0.09;

                if ($this.page != Math.round(numb) + 1) {
                  $this.page = Math.round(numb) + 1;
                }
              },
              false
            );
        }
      });
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const resp = await this.$axios.post(
        `/api/CustomObject/answer_by_custom_object_id`,
        this.payload
      );

      this.data = resp.detail || [];

      this.isLoading = false;
      this.isCardView =
        this.$route.query.view == 1
          ? true
          : this.$route.query.view == 0
          ? false
          : true;
    },
    gotoForm() {
      this.$router.push(
        "/form/" + this.formId + "?edit=true&view=" + (this.isCardView ? 1 : 0)
      );
    },
    countDate(date) {
      let time = this.$moment(date).to(this.$moment(), true);
      // let year = this.$moment(date).diff(this.$moment(), "year");
      // let month = this.$moment(date).diff(this.$moment(), "month");
      // let days = this.$moment(date).diff(this.$moment(), "day");
      // return `${year} ปี ${month} เดือน ${days} วัน`;
      return time;
    },
    getImage(data) {
      if (data.datas.find(el => el.field_type_id == 8))
        return (
          data.datas.find(el => el.field_type_id == 8).answer_detail[0]
            .value_answer || require("@/assets/images/default-image.png")
        );
      else return require("@/assets/images/default-image.png");
    },
    async deleteForm(id, index) {
      this.$swal({
        title: "คุณต้องการลบ หรือไม่ ?",
        text: "",
        icon: "warning",
        buttons: {
          confirm: { text: "ยืนยัน", className: "sweet-warning" },
          cancel: "ยกเลิก"
        },
        dangerMode: true
      }).then(async willDelete => {
        if (willDelete) {
          const resp = await this.$axios.delete(
            `/api/CustomObject/delete_answer/${id}`
          );

          if (resp.result == 1) {
            this.data.splice(index, 1);
            this.$emit("fetching");
          } else {
            this.$swal(this.$t("error_message"), resp.message, "error");
          }
        }
      });
    },
    editForm(id) {
      this.$router.push(
        `/form/${this.formId}?ans_id=${id}&edit=true&view=${
          this.isCardView ? 1 : 0
        }`
      );
    },
    clickPage(n) {
      let width = document.getElementsByClassName("v-card")[0].offsetWidth;
      document.getElementsByClassName("horizontal-snap")[0].scrollLeft =
        width * (n - 1);
      this.page = n;
    }
  }
};
</script>

<style>
@import "./style/index.css";
</style>
