<template>
  <template v-if="!isAddOptionalTelephone">
    <template v-if="!isCreateInvoice">
      <div class="p-3">
        <h4 class="f-bold" data-lang="shipping">{{ $t("shipping") }}</h4>
        <div class="panel-invoice">
          <template v-for="address of list" :key="address.id">
            <div class="container-invoice" @click="getById(address.id)">
              <div class="color-primary">{{ address.address_name }}</div>
              <div class="text-bold text-black">
                {{ address.prefix + " " || "" }}{{ address.first_name }}
                {{ address.last_name }}
                <span class="color-gray"
                  >{{ convertTelePhone(address.telephone) }}
                </span>
              </div>
              <p class="color-gray mb-0 f-md">
                <span v-html="displayAddress(address)"></span>
                <!-- <template v-if="address.address">{{
                  address.address
                }}</template>
                <template v-if="address.building_name">
                  {{ address.building_name }}</template
                >
                <template v-if="address.alley">
                  ซอย{{ address.alley }}</template
                >
                <template v-if="address.road"> ถนน{{ address.road }}</template>
                <template v-if="address.sub_district">
                  แขวง{{ address.sub_district }}</template
                >
                <template v-if="address.district">
                  เขต{{ address.district }}
                </template>
                <template v-if="address.province">
                  &nbsp;{{ address.province }}
                </template>
                <template v-if="address.zip_code">
                  &nbsp;{{ address.zip_code }}
                </template>
                <template v-if="address.country">
                  &nbsp;{{ address.country }}
                </template> -->
                <br />
                <span class="text-bold text-black">
                  ขนส่งโดย :
                  {{
                    address.shipping_type == "0" ? "-" : address.shipping_type
                  }}</span
                >
              </p>
              <div class="box-default" v-if="address.is_default == 1">
                Default
              </div>
            </div>
          </template>

          <div
            class="container-invoice add"
            @click="addInvoiceAddress"
            v-if="list.length < limit || limit == 0"
          >
            <div
              class="d-flex justify-content-center align-items-center"
              style="flex: 1"
            >
              <font-awesome-icon :icon="['fas', 'plus']" class="mr-2" />
              <span data-lang="add_shipping">{{ $t("add_shipping") }}</span>
              {{ limit > 0 ? `(${list.length}/${limit})` : "" }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="content-between px-3 py-2">
        <div class="d-flex align-items-center" @click="back()">
          <font-awesome-icon
            icon="chevron-left"
            class="mr-2"
          ></font-awesome-icon>
          <div class="f-bold f-xl" data-lang="info_shipping">
            {{ $t("info_shipping") }}
          </div>
        </div>
        <div class="f-bold f-xl"></div>

        <div
          @click="fillWithProfile()"
          class="text-underline f-sm color-primary mr-2"
        >
          <span data-lang="use_as_profile">{{ $t("use_as_profile") }}</span>
        </div>
      </div>
      <div class="content-edit-profile px-3 pb-3">
        <div v-html="$config.header_shipping_address_form"></div>

        <v-expansion-panels flat v-model="panels" multiple>
          <v-expansion-panel bg-color="white">
            <v-expansion-panel-title static hide-actions flat class="px-0">
              <template #default="{ expanded }">
                <div class="content-between w-100">
                  <div class="f-xl f-bold" data-lang="profile_address">
                    {{ $t("profile_address") }}
                  </div>
                  <div>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-up']"
                      v-if="expanded"
                    />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-else />
                  </div>
                </div>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text eager>
              <AddressForm
                ref="addressForm"
                :fields="
                  customField.filter(
                    el =>
                      el.field_shipping_type_id != 18 &&
                      el.field_shipping_type_id != 17
                  )
                "
                :cloneFields="
                  cloneFields.filter(
                    el =>
                      el.field_shipping_type_id != 18 &&
                      el.field_shipping_type_id != 17
                  )
                "
                :key="componentKey"
                :v$="v$"
                :form="form"
                fieldId="field_shipping_type_id"
                :mapField="mapField"
                defaultId="19"
                @addOptionalTelephone="addOptionalTelephone"
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel bg-color="white">
            <v-expansion-panel-title static hide-actions flat class="px-0">
              <template #default="{ expanded }">
                <div class="content-between w-100">
                  <div class="f-xl f-bold">{{ $t("shipping_methods") }}</div>
                  <div>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-up']"
                      v-if="expanded"
                    />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-else />
                  </div>
                </div>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text eager>
              <AddressForm
                ref="addressForm"
                :fields="
                  customField.filter(
                    el =>
                      el.field_shipping_type_id == 18 ||
                      el.field_shipping_type_id == 17
                  )
                "
                :cloneFields="
                  cloneFields.filter(
                    el =>
                      el.field_shipping_type_id == 18 ||
                      el.field_shipping_type_id == 17
                  )
                "
                :v$="v$"
                :form="form"
                fieldId="field_shipping_type_id"
                :mapField="mapField"
                defaultId="19"
                @addOptionalTelephone="addOptionalTelephone"
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
          <v-expansion-panel bg-color="white" v-if="socialField.length > 0">
            <v-expansion-panel-title static hide-actions flat class="px-0">
              <template #default="{ expanded }">
                <div class="content-between w-100">
                  <div class="f-xl f-bold" data-lang="social_media">
                    {{ $t("social_media") }}
                  </div>
                  <div>
                    <font-awesome-icon
                      :icon="['fas', 'chevron-up']"
                      v-if="expanded"
                    />
                    <font-awesome-icon :icon="['fas', 'chevron-down']" v-else />
                  </div>
                </div>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text eager>
              <DynamicForm
                :fields="socialField"
                :v$="v$"
                keyFormValidate="socialField"
            /></v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <div style="margin: 0 -10px">
          <v-checkbox
            v-model="form.is_default"
            :value="1"
            :label="$t('default')"
            hide-details
            single-line
            :false-value="0"
          ></v-checkbox>
        </div>
        <div
          v-html="$config.footer_shipping_address_form"
          class="pt-3 pb-5"
        ></div>
      </div>
      <div
        class="register-bottom fixed-bottom p-2 shadow"
        :class="{ 'bg-white': !lineLayout.includes('Minimal') }"
      >
        <c-btn
          class="w-100 mb-2"
          variant="outlined"
          @click="$refs.confirmModal.show()"
          v-if="form.id"
        >
          <span data-lang="delete">{{ $t("delete") }}</span>
        </c-btn>
        <c-btn class="w-100" variant="confirm-primary" @click="submit()">
          <span data-lang="save">{{ $t("save") }}</span>
        </c-btn>
      </div>
    </template>
  </template>

  <OptionalTelephone
    v-else
    :form="form"
    @saveOptionalTelephone="saveOptionalTelephone"
    @close="closeOptionalTelephone"
  />
  <ModalConfirm
    ref="confirmModal"
    :header="messageModal.header"
    :text="messageModal.text"
    :subText="messageModal.subText"
    :button="messageModal.button"
    @handler="deleteAddress()"
  />
  <Loading v-if="isLoading" />
  <ModalAlert ref="modalAlert" :text="msg" @hide="back" closeText="ยืนยัน" />
  <ModalError ref="modalError" :text="modalMessage" />
</template>

<script>
import AddressForm from "@/components/form/AddressForm.vue";
import { shippingField, mapShippingField } from "@/assets/json/fieldAddress.js";
import { useVuelidate } from "@vuelidate/core";
import DynamicForm from "@/components/form/DynamicForm.vue";
import ModalAlert from "@/components/alert-modal/ModalAlert.vue";
import ModalError from "@/components/alert-modal/ModalError.vue";
import OptionalTelephone from "@/components/form/OptionalTelephone.vue";
import {
  requiredIf,
  helpers,
  required,
  maxLength,
  minLength,
  numeric,
  email
} from "@vuelidate/validators";

export default {
  name: "InvoicePage",
  components: {
    AddressForm,
    ModalAlert,
    ModalError,
    OptionalTelephone,
    DynamicForm
  },
  data() {
    return {
      isCreateInvoice: false,
      customField: [],
      socialField: [],
      cloneFields: [],
      isLoading: false,
      loading: false,
      list: [],
      msg: "สำเร็จ",
      modalMessage: "",
      form: {
        user_guid: this.$store.state.shortProfile.user_guid,
        address_name: "",
        prefix: "",
        first_name: "",
        last_name: "",
        optional_telephone: "",
        telephone: "",
        country: "ประเทศไทย",
        telephone_country_id: 1,
        address: "",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        shipping_type: "",
        note: "",
        email: "",
        is_default: 0,
        social: [],
        custom_field: []
      },
      mapField: [...mapShippingField],

      limit: 0,
      isAddOptionalTelephone: false,
      IsSetField: false,
      panels: [0, 1, 2],
      messageModal: {
        header: "ยืนยันการลบ ?",
        text: "คุณต้องการลบข้อมูลที่อยู่จัดส่งนี้หรือ ไม่",
        subText: "",
        button: {
          true: {
            class: ["btn-danger"],
            msg: this.$t("confirm")
          },
          false: {
            class: ["btn-secondary"],
            msg: this.$t("cancel")
          }
        }
      },
      componentKey: 0
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    for (const item of this.customField) {
      const key = this.checkIsProfile(item.field_shipping_type_id);

      if (key) {
        if (item.field_shipping_type_id == 5)
          shippingField[key] = {
            required: requiredIf(item.required),
            email
          };
        else if (item.field_shipping_type_id == 6)
          shippingField[key] = {
            required: requiredIf(item.required),
            // numeric,
            minLength: minLength(9)
            // maxLength: maxLength(10)
          };
        else shippingField[key] = { required: requiredIf(item.required) };
      }
    }
    const checkValue = (val1, v, a) => {
      if (v.required)
        if (!val1) return true;
        else return false;
    };

    return {
      form: shippingField,

      socialField: {
        $each: helpers.forEach({
          user_answer: {
            required: requiredIf(checkValue)
          }
        })
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    addOptionalTelephone() {
      this.isAddOptionalTelephone = true;
    },
    saveOptionalTelephone(val) {
      this.form.optional_telephone = val.join(",");
      this.isAddOptionalTelephone = false;
    },

    closeOptionalTelephone() {
      this.isAddOptionalTelephone = false;
    },
    checkIsProfile(val) {
      const findField = mapShippingField.find(el => el.id == val);

      return findField ? findField.key : false;
    },
    addInvoiceAddress() {
      this.isCreateInvoice = true;
      this.getForm();
    },
    async getList() {
      const result = await this.$axios(
        `/api/v1/user/GetShippingInformationList`
      );

      this.list = result.detail.result;
      this.limit = result.detail.limit;
    },
    async getById(id) {
      this.isLoading = true;
      const result = await this.$axios(
        `/api/v1/user/GetShippingInformationById/${id}`
      );

      this.form = result.detail.data;
      this.form.social = result.detail.social;
      await this.getForm();
      this.isCreateInvoice = true;
      this.isLoading = false;
    },
    async getForm() {
      this.isLoading = true;
      const result = await this.$axios("/api/v1/User/GetShippingForm");
      for (const $fields of result.detail.profileField) {
        let $disable = result.detail.profileField.find(
          el => el.field_dependent == $fields.field_shipping_type_id
        );
        if ($fields.field_shipping_type_id == 13)
          $fields.field_choices = await this.$store.dispatch("getProvince");

        if ($disable && $fields.field_type_id == 15) {
          $fields.field_choices = [];
        }
        if ($fields.field_shipping_type_id == 17) {
          $fields.field_choices = result.detail.shippingMethod.map(el => {
            return { display_name: el.name, is_display: 1 };
          });
        }
      }
      this.cloneFields = JSON.parse(JSON.stringify(result.detail.profileField));
      this.dynamicField = result.detail.customField.filter(
        el => el.is_show == 1
      );
      this.mapSocialField(
        result.detail.socialField
          .map(el => {
            return { ...el, social_media_type_id: el.id };
          })
          .filter(el => el.is_show == 1)
      );
      this.customField = result.detail.profileField.filter(
        el => el.is_show == 1
      );

      this.isLoading = false;
      // console.log(result);
    },
    async submit() {
      try {
        this.v$.form.$touch();
        this.v$.socialField.$touch();
        if (this.v$.socialField.$error || this.v$.form.$error) return;
        this.isLoading = true;
        let result = "";
        let dynamic = [];
        for (const fields of this.dynamicField) {
          let target_id = 0;
          if (fields.field_choices.length > 0) {
            const findFieldId = fields.field_choices.find(
              choice => choice.name == fields.user_answer
            );
            if (findFieldId) {
              target_id = findFieldId.id;
            }
          }
          dynamic.push({
            id: fields.id,
            value: fields.user_answer,
            target_id: target_id
          });
        }
        let objTemp = [];

        for (const x of dynamic) {
          var findField = this.dynamicField.find(field => field.id == x.id);

          if (x.value && typeof x.value == "object") {
            if (x.value.length > 0) {
              for (const a of x.value) {
                const { id } = findField.field_choices.find(
                  choice => choice.name == a
                );
                objTemp.push({
                  id: x.id,
                  value: a,
                  target_id: id
                });
              }
            }
          } else {
            objTemp.push(x);
          }
        }
        let payload = {
          ...this.form,
          custom_field: objTemp.filter(el => el.value)
        };
        payload.social = this.socialField.map(el => {
          return {
            id: el.id,
            social_media_type_id: el.social_media_type_id,
            value: el.user_answer,
            user_guid: payload.user_guid
          };
        });

        for (const checkForm of this.customField) {
          if (this.isOtherField(checkForm))
            payload[this.checkIsProfile(checkForm.field_shipping_type_id)] =
              checkForm.other_value;
        }
        // payload.shipping_type = 0;
        if (this.form.id) {
          result = await this.$axios.put(
            "/api/v1/User/updateShippingInformation",
            payload
          );
        } else {
          result = await this.$axios.post(
            "/api/v1/User/CreateShippingInformation",
            payload
          );
        }

        this.isLoading = false;
        if (result.result) {
          this.$refs.modalAlert.show();
          this.getList();
          // this.back();
        } else {
          this.modalMessage = result.message;
          this.$refs.modalError.show();
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.modalMessage = error.message;
        this.$refs.modalError.show();
      }
    },
    isOtherField($fields) {
      if ($fields) {
        let find = $fields.field_choices.find(
          el =>
            el.name ==
            this.form[this.checkIsProfile($fields.field_shipping_type_id)]
        );
        return find ? (find.choice_type_id == 3 ? true : false) : false;
      }
    },
    back() {
      this.form = {
        user_guid: this.$store.state.shortProfile.user_guid,
        address_name: "",
        prefix: "",
        first_name: "",
        last_name: "",
        optional_telephone: "",
        telephone: "",
        country: "ประเทศไทย",
        telephone_country_id: 1,

        address: "",
        building_name: "",
        alley: "",
        road: "",
        sub_district: "",
        district: "",
        province: "",
        zip_code: "",
        shipping_type: "",
        note: "",
        email: "",
        is_default: 0,
        social: []
      };
      this.v$.form.$reset();
      this.isCreateInvoice = false;
    },
    async fillWithProfile() {
      this.isLoading = true;
      let profileField = JSON.parse(
        JSON.stringify(this.$store.state.shortProfile)
      );

      let clone = this.form.social.length > 0 ? [...this.form.social] : null;

      if (profileField.is_company == 1)
        this.form = await {
          ...this.form,
          ...profileField,
          prefix: "",
          first_name: "",
          last_name: "",
          sub_district: profileField.subdistrict,
          address: profileField.home_address,
          building_name: profileField.town,
          country: profileField.country || "ประเทศไทย"
        };
      else {
        this.form = await {
          ...this.form,
          ...profileField,
          // prefix: "",
          first_name: profileField.first_name_th,
          last_name: profileField.last_name_th,
          sub_district: profileField.subdistrict,
          address: profileField.home_address,
          building_name: profileField.town,
          country: profileField.country || "ประเทศไทย"
        };
      }
      // console.log(this.form.social);

      if (!this.form.social)
        this.form.social = await this.$store.dispatch("getUserSocial");
      this.form.social = this.form.social.map(el => {
        return { ...el, id: 0 };
      });

      this.mapSocialField(this.socialField, clone);
      await this.$nextTick(
        async () => await this.$refs.addressForm.loadingField()
      );
      // await this.$nextTick(
      // );
      this.componentKey += 1;
      this.isLoading = false;
    },
    async mapSocialField(array, clone) {
      this.socialField = array.map((el, index) => {
        let answer = null;

        if (this.form.social)
          answer = this.form.social.find(
            form => form.social_media_type_id == el.social_media_type_id
          );

        return {
          ...el,
          answer_id: 0,
          id: clone ? clone[index].id : answer ? answer.id : 0,
          social_media_type_id: el.social_media_type_id,
          field_type_id: 1,
          user_answer: answer ? answer.value : ""
        };
      });
    },
    async deleteAddress() {
      this.isLoading = true;
      const result = await this.$axios.delete(
        `/api/v1/User/DeleteShippingInformation/${this.form.id}`
      );
      this.isLoading = false;
      if (result.result) {
        this.$refs.modalAlert.show();
        this.getList();
        // this.back();
      } else {
        this.modalMessage = result.message;
        this.$refs.modalError.show();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container-invoice {
  min-height: 100px;
  position: relative;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid whitesmoke;
  /* align-items: center; */
  /* justify-content: center; */
  &.add {
    border: 1px dashed var(--primary-color);
    color: var(--primary-color);
  }
  &.disabled {
    border: 1px dashed var(--disabled-color);
    color: var(--disabled-color);
  }
}
.panel-invoice {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.box-default {
  color: white;
  background-color: green;
  border: 1px solid green;
  border-radius: 0.3rem;
  position: absolute;
  top: 10%;
  right: 3%;
  font-size: 12px;
  padding: 0 5px;
}
</style>
