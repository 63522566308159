<template>
  <div>
    <div class="bg-menulist">
      <div
        class="menu-list"
        v-for="list of MenuList"
        :key="list.id"
        @click="checkMenuType(list)"
        :style="`--bg-color:${list.bg_color || 'white'}`"
      >
        <v-row class="align-items-center">
          <v-col cols="3" class="text-center">
            <span class="px-3">
              <template v-if="list.path == 'reward-transaction'">
                <div class="position-reletive d-inline">
                  <div class="random-reward" style="top: 10%">
                    <font-awesome-icon :icon="['fas', 'gift']" class="ft-16" />
                  </div>
                  <div class="random-reward" style="top: 2%">
                    <font-awesome-icon
                      :icon="['fas', 'expand']"
                      class="expand"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <font-awesome-icon :icon="['fas', list.icon]" class="ft-20" />
              </template>
            </span>
          </v-col>
          <v-col class="p-0" cols="5">
            <span>{{ list.customer_menu_language[0].name }}</span>
          </v-col>
          <v-col cols="2" class="text-center">
            <v-badge
              color="error"
              pill
              inline
              :content="
                list.path == 'privilege'
                  ? profile.total_coupon_can_use
                  : profile.total_random_reward
              "
              class="px-2 mb-1"
              v-show="
                (profile.total_random_reward &&
                  list.path == 'reward-transaction') ||
                (profile.total_coupon_can_use && list.path == 'privilege')
              "
            >
            </v-badge>
          </v-col>
          <v-col cols="2">
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="icon-color"
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <ModalLoading ref="modalLoading" />
  </div>
</template>

<script>
import ModalLoading from "@/components/other/ModalLoading.vue";
export default {
  components: {
    ModalLoading
  },
  props: ["MenuList", "profile"],
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    checkMenuType(item) {
      if (item.customer_menu_type_id == 1 || item.customer_menu_type_id == 0) {
        if (item.id == 6 || item.id == 7) return this.$router.push(item.path);
        return this.$router.push(item.path + `?title=${item.name}`);
      } else if (item.customer_menu_type_id == 2) {
        function isValidHttpUrl(string) {
          let url;

          try {
            url = new URL(string);
          } catch (_) {
            return false;
          }

          return url.protocol === "http:" || url.protocol === "https:";
        }
        let check = isValidHttpUrl(item.path);

        return !check
          ? window.open("https://" + item.path)
          : window.open(item.path);
      }
      return this.$router.push(item.path + `?title=${item.name}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-menulist {
  // background-color: #f1f1f1;
  .menu-list {
    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.bg-menulist .menu-list:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}
.menu-list {
  background-color: var(--bg-color);
  padding: 20px 0;
}
.menu-list-bottom {
  padding: 20px 0;
}
.ft-20 {
  font-size: var(--text-xl);
}
.icon-color {
  color: #c9c9c9;
}
.random-reward {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.expand {
  font-size: 30px;
}
.position-reletive {
  position: relative;
}
</style>
