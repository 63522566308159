<template>
  <div>
    <div class="div-input">
      <div :class="['input-custom', { error: isValidate }]">
        <label>
          {{ textFloat }}
          <span v-if="isRequired" class="text-danger">*</span>
        </label>
        <div class="d-flex justify-content-center align-items-center">
          <div class="w-100 display-only">
            <input
              :class="['custom-input', readonly ? 'disabled' : '']"
              :type="type.image"
              :placeholder="placeholder"
              :name="name"
              :required="required"
              :value="fileName"
              :style="`border-color: ${$store.state.theme.themePrimaryColor};`"
              disabled
            />
            <div
              class="delete-icon"
              @click="clearValue"
              v-if="fileName && !readonly"
            >
              <font-awesome-icon
                icon="times"
                class="text-error"
              ></font-awesome-icon>
            </div>
          </div>
          <label
            class="mb-0 btn-main"
            :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
            v-if="!readonly"
          >
            <input
              type="file"
              v-on:change="handleFileChange"
              :required="required"
              :accept="accept"
              :name="name"
              ref="input"
              :disabled="disabled"
            />
            เลือกไฟล์
          </label>
          <label
            class="mb-0 btn-main"
            :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
            @click="previewImage()"
            v-else
          >
            <font-awesome-icon icon="search"></font-awesome-icon>
          </label>
        </div>
      </div>

      <div v-if="v && v.$error">
        <span class="text-error" v-if="v.required == false"
          >Please select cover image.</span
        >
      </div>
    </div>
    <v-dialog
      v-model="previewModal"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      content-class="bg-transparent overflow-visible"
    >
      <PDF v-if="fileName.includes('pdf')" :src="fileName" />
      <c-img v-else :src="fileName" class="w-100 shadow" ratio="16/9" />
      <div @click="previewModal = false" class="close-icon">
        <font-awesome-icon icon="times"></font-awesome-icon>
      </div>
    </v-dialog>
    <ModalError ref="modalError" :text="modalMessage" :desc="modalDesc" />
  </div>
</template>

<script>
import PDF from "pdf-vue3";
import ModalError from "@/components/alert-modal/ModalError";
import { resizeImage } from "@/services/resize-image.js";
export default {
  components: {
    ModalError,
    PDF
  },
  props: {
    textFloat: {
      required: true,
      type: String
    },
    // text: {
    //   required: true,
    //   type: String
    // },
    fileName: {
      required: false,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    name: {
      required: false,
      type: String
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    placeholder: {
      required: true,
      type: String
    },
    downloadPath: {
      required: false,
      type: String
    },
    v: {
      required: false,
      type: Object
    },
    disabled: {
      required: false,
      type: Boolean
    },
    accept: {
      required: true,
      type: String
    },
    validateFileType: {
      required: false,
      type: String
    },
    readonly: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      file: "",
      value: "",
      type: {
        all: ["image/jpeg", "image/png", "video/mp4"],
        file: ["image/jpeg", "image/png", "application/pdf"],
        pdf: ["application/pdf"],
        png: ["image/png"],
        image: ["image/jpeg", "image/png"],
        video: ["video/mp4"]
      },
      modalMessage: "",
      modalDesc: "",
      previewModal: false,
      fileType: null
    };
  },
  methods: {
    clearValue(e) {
      this.filename = "";
      this.$refs.input.value = "";

      this.$emit("onFileChange", "");
    },

    async handleFileChange(e) {
      this.value = e.target.files[0];
      var _validFileExtensions = this.accept;
      if (e.target.files.length) {
        var reader = new FileReader();

        reader.readAsDataURL(e.target.files[0]);

        reader.onload = async () => {
          var resize = null;
          if (this.type.image.includes(this.value.type)) {
            resize = await resizeImage(reader.result);
          }
          let size = resize ? resize.size : this.value.size;
          var filesize = (size / 1024 / 1024).toFixed(4);
          if (_validFileExtensions.indexOf(this.value.type) < 0) {
            this.$refs.input.value = "";
            this.modalMessage = "File not support";
            this.modalDesc = `(Only ${this.validateFileType})`;
            this.$refs.modalError.show();
          } else if (filesize > 2.5) {
            this.modalMessage = "This file size is too large";
            this.modalDesc = "(Maximun Size is 2.5mb)";
            this.$refs.modalError.show();
          } else {
            this.$emit("onFileChange", this.value);
          }
        };
      } else {
        this.value = "";
        this.$refs.input.value = "";
      }
    },
    async previewImage() {
      this.previewModal = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-upload {
  position: relative;
  margin-bottom: 15px;
}
.icon-add {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  height: 25px;
}
.icon-delete {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #707070;
  z-index: 1;
}
.label-file {
  top: 0px;
  position: absolute;
  right: 60px;
  margin-right: 5px;
}
.panel-bg-file-img {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;
  /* padding-bottom: 20%; */
  height: 100px;
  border: 2px dashed #40a9d1;
  border-radius: 6px;
  width: 100px;
  cursor: pointer;
}
.icon-camera {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #afce38;
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
}
input[type=file], /* FF, IE7+, chrome (except button) */
  input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
  width: 100%;
  height: 100%;
  line-height: 95px;
  position: absolute;
  opacity: 0;
}
input[readonly] {
  background-color: var(--disable-color);
}
.detail-format {
  color: #9b9b9b;
  font-size: var(--text-sm);
  font-family: "Kanit-Light";
  margin-top: 3px;
  margin-bottom: 0px;
  /* height: 35px; */
}
.div-input {
  // margin-bottom: 15px;
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}

input.custom-input {
  background-color: white;
  border: 1px solid #bcbcbc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 7px 10px;
}
input.custom-input.disabled {
  background-color: var(--disable-color);
}
label[size="lg"].btn-main,
input[size="lg"].custom-input {
  height: 45px;
}
.input-custom.error .custom-input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: var(--text-lg);
  font-family: "Kanit-Light";
}
.display-only {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.btn-main {
  //   width: 120px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  //   background: #16274a;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  padding: 8px 10px;
}
.btn-download-file {
  padding: 0;
  text-decoration: underline;
}
.delete-icon {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}
.close-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  right: 10px;
  background: white;
  border-radius: 50%;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
