<template>
  <div class="content-page">
    <template v-if="!isLoading">
      <LayoutNewDesign v-if="layoutDisplay == 'Compact'" />
      <LayoutMinimal v-else-if="layoutDisplay == 'Minimal'" />
      <Layout v-else :profile="profile" />
    </template>
    <div v-show="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <template
      v-for="(banner, index) of customFormList"
      :key="banner.id + index"
    >
      <ModalCustomForm
        :data="banner"
        :ref="'modalCustomForm-' + index"
        :index="index"
        @hidden="handleModalClose(banner.id)"
      />
    </template>
    <ModalRegisterAlert ref="ModalRegisterAlert" />
  </div>
</template>

<script>
import { Layout, LayoutNewDesign, LayoutMinimal } from "./Layout";
import ModalCustomForm from "@/components/profile/modal/ModalCustomForm";
import ModalRegisterAlert from "@/components/profile/modal/ModalRegisterAlert";
import UploadFile from "@/components/custom-fields/fields/UploadFile";
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    Layout,
    LayoutNewDesign,
    LayoutMinimal,
    ModalCustomForm,
    ModalRegisterAlert,
    UploadFile,
    OtherLoading
  },
  async created() {
    this.profile = await this.getProfile();
    this.getCustomObject();
    this.isLoading = false;
  },
  mounted() {
    this.$refs.ModalRegisterAlert.show();
  },
  data() {
    return {
      layoutDisplay: this.lineLayout,
      isLoading: true,
      customFormList: [],
      profile: {}
    };
  },
  methods: {
    async getProfile() {
      return new Promise(async resolve => {
        const res = await this.$store.dispatch("getUserProfile", true);
        return resolve(res);
      });
    },
    async getCustomObject() {
      try {
        const data = await this.$axios(
          `/api/CustomObject/custom_object_register_page`
        );

        let id = this.$cookies.get("modalShowId") || [];
        this.customFormList = await data.detail.filter(
          el => !id.includes(el.id)
        );

        if (this.customFormList.length > 0)
          this.$nextTick(() => this.$refs["modalCustomForm-0"][0].show());
      } catch (err) {
        console.log("getCustomObject", err.message);
      }
    },
    handleModalClose(id) {
      let cid = this.$cookies.get("modalShowId") || [];
      this.$cookies.set("modalShowId", [...cid, id]);
      this.bannerIndex += 1;
      if (this.$refs[`modalCustomForm-${this.bannerIndex}`])
        this.$refs[`modalCustomForm-${this.bannerIndex}`][0].show();
    }
  }
};
</script>

<style lang="scss" scoped></style>
