<template>
  <div>
    <v-dialog
      v-model="showModal"
      ref="alertModal"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      @hidden="hide"
    >
      <template v-if="lineLayout == 'Compact'">
        <div class="text-center mb-3">
          <p class="text-text f-xl" v-if="text">{{ text }}</p>
        </div>
        <template v-if="note">
          <div class="mb-2">
            <span class="f-bold f-md">หมายเหตุ *</span> <br />
            <span class="f-sm">
              {{ note }}
            </span>
          </div>
        </template>
        <div class="text-center" v-if="showIcon">
          <font-awesome-icon icon="check-circle" size="5x" class="mb-3" />
        </div>
      </template>
      <template v-else>
        <div class="text-center" v-if="showIcon">
          <font-awesome-icon icon="check-circle" size="5x" class="mb-3" />
        </div>
        <div class="text-center mb-3">
          <p :class="[showIcon ? 'text-text f-xl' : 'f-md']" v-if="text">
            {{ text }}
          </p>
        </div>
        <template v-if="note">
          <div class="mb-2">
            <span class="f-bold f-md">หมายเหตุ *</span> <br />
            <span class="f-sm">
              {{ note }}
            </span>
          </div>
        </template>
      </template>

      <!-- <div class="border-top p-2 text-center btn-confirm-popup"> -->
      <c-btn block variant="confirm-primary" @click="close()">
        {{ closeText || $t("confirm") }}
      </c-btn>
      <!-- </div> -->
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: false,
      type: String
    },
    closeText: {
      required: false
    },
    note: {
      required: false,
      default: ""
    },
    showIcon: {
      required: false,
      default: true
    },
    preventHideEvent: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  watch: {
    showModal(val) {
      if (!val && !this.preventHideEvent) this.$emit("hide");
    }
  },
  methods: {
    show() {
      this.showModal = true;
      this.$emit("show");
    },
    hide() {
      this.showModal = false;
      // this.$emit("hide");
    },
    close() {
      this.showModal = false;
      this.$emit("hide");
    }
  }
};
</script>

<style rel="scss" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-text {
  /* color: #000; */
  margin-bottom: 0;

  font-weight: 600;
}
.word-space {
  white-space: break-spaces;
}
.modal-body {
  margin: auto;
  text-align: center;
}
.class-icon-black {
  width: 70px;
}
svg[data-icon="check-circle"] {
  color: var(--primary-color);
}
</style>
