<template>
  <v-btn
    :block="block"
    :size="size"
    v-if="typeof icon == 'boolean'"
    :rounded="typeof pill == 'string' ? 'xl' : 'md'"
    :variant="variant"
    :flat="flat"
    :ripple="ripple"
    :readonly="disabled"
  >
    <slot />
  </v-btn>
  <v-btn
    density="compact"
    :block="block"
    v-else
    :variant="variant"
    :flat="flat"
    icon
    size="x-large"
    :ripple="ripple"
    :readonly="disabled"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "CustomButton",
  props: {
    block: { required: false, default: () => false },
    pill: {
      required: false,
      default: () => false
    },
    icon: {
      required: false,
      default: false
    },
    variant: {
      required: false,
      default: "flat",
      type: String
    },
    flat: {
      required: false,
      default: false
    },
    ripple: {
      required: false,
      default: false
    },
    size: {
      required: false,
      default: "default"
    },
    disabled: {
      required: false,
      type: Boolean
    }
  }
};
</script>

<style></style>
