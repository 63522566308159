<template>
  <div class="summary-container">
    <c-btn variant="text" @click="$emit('close')" class="px-0">
      <font-awesome-icon
        icon="chevron-left"
        size="sm"
        class="mr-2"
      ></font-awesome-icon
      >BACK</c-btn
    >
    <v-card flat>
      <div class="text-center f-bold">PURCHASE SUMMARY</div>
      <v-divider class="mb-1 mt-6"></v-divider>
      <v-list>
        <v-list-item>
          <v-list-item-subtitle>
            <span>PURCHASE ID</span>
            <span>{{ transaction.invoice_no }} </span></v-list-item-subtitle
          >

          <v-list-item-subtitle>
            <span>STAFF ID</span>
            <span>{{ transaction.staff_name }}</span></v-list-item-subtitle
          >

          <v-list-item-subtitle>
            <span>DATE & TIME</span>
            <span>{{
              $moment(transaction.transaction_date)
                .locale("en")
                .format("DD/MM/YYYY, HH:mm A")
            }}</span></v-list-item-subtitle
          >

          <v-list-item-subtitle>
            <span>STORE NAME</span>
            <span>{{ transaction.branch_name }}</span></v-list-item-subtitle
          >
        </v-list-item>
      </v-list>
      <v-divider class="my-1"></v-divider>
      <v-list class="product-transaction">
        <v-list-item
          v-for="(item, index) in transaction.transaction_details"
          :key="index"
        >
          <v-list-item-title>{{ item.product_name }}</v-list-item-title>
          <v-list-item-subtitle>
            Price

            <div>
              <span
                v-if="item.discount_value_piece"
                class="mr-2 discount-price price"
                >{{ numeralFormat(item.before_discount) }}</span
              ><span
                class="price"
                :class="{ 'text-danger': item.discount_value_piece }"
                >{{ numeralFormat(item.after_discount_price) }}</span
              >
            </div>
          </v-list-item-subtitle>

          <v-list-item-subtitle v-if="item.note"
            >{{ $t("purchase_note") }} : {{ item.note }}</v-list-item-subtitle
          >
          <v-list-item-subtitle
            >Qty <span>{{ item.quantity }}</span></v-list-item-subtitle
          >
          <v-divider class="my-1"></v-divider>
        </v-list-item>
      </v-list>

      <v-list class="product-transaction">
        <v-list-item>
          <v-list-item-subtitle>
            <span>ORDER VALUE</span>
            <span class="price">{{
              numeralFormat(transaction.total_price)
            }}</span></v-list-item-subtitle
          >

          <v-list-item-subtitle>
            <span class="text-danger">DISCOUNT (DISCOUNT CODE)</span>
            <span class="text-danger"
              ><template v-if="transaction.discount > 0">-</template>฿
              {{ numeralFormat(transaction.discount) }}</span
            ></v-list-item-subtitle
          >
        </v-list-item>
        <v-divider class="my-1"></v-divider>
        <v-list-item>
          <v-list-item-subtitle class="font-weight-bold">
            <span>TOTAL inc. taxes</span
            ><span class="price">{{
              numeralFormat(transaction.grand_total)
            }}</span></v-list-item-subtitle
          >
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    transactionId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      summary: {
        purchaseId: "COS12345689",
        staff: "Jamal Hithai",
        dateTime: "24/08/2025, 04:00 PM",
        store: "COS Siam Paragon",
        items: [
          {
            name: "TORTOISESHELL-JACQUARD",
            price: "฿2,990",
            size: "M / L",
            color: "Red",
            qty: 2,
            discounted: false
          },
          {
            name: "THE WAISTED LINEN BLAZER",
            price: "฿3,990 ฿2,990",
            size: "M / L",
            color: "Beige",
            qty: 1,
            discounted: true
          }
        ],
        orderValue: "฿X,XXX",
        tax: "฿X,XXX",
        discount: "-฿X,XXX",
        total: "฿5,980"
      },
      transaction: {
        purchaseId: "COS12345689",
        staff: "Jamal Hithai",
        dateTime: "24/08/2025, 04:00 PM",
        store: "COS Siam Paragon",
        items: [
          {
            name: "TORTOISESHELL-JACQUARD",
            price: "฿2,990",
            size: "M / L",
            color: "Red",
            qty: 2,
            discounted: false
          },
          {
            name: "THE WAISTED LINEN BLAZER",
            price: "฿3,990 ฿2,990",
            size: "M / L",
            color: "Beige",
            qty: 1,
            discounted: true
          }
        ],
        orderValue: "฿X,XXX",
        tax: "฿X,XXX",
        discount: "-฿X,XXX",
        total: "฿5,980"
      }
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData(id) {
      this.emitter.emit("showLoading");
      await this.$axios(
        `/api/Transaction/customer_transaction/${this.transactionId}`
      ).then(async data => {
        this.transaction = data.detail;
        this.emitter.emit("hideLoading");
        // if (data.detail.url_pdf.includes(".pdf")) this.renderingPDF();
      });
    }
  }
};
</script>

<style scoped>
.summary-container {
  width: 100%;
  /* margin: auto; */
  /* height: calc(100vh - 80px); */
  overflow-y: auto;
  padding: 20px;
}
.discount-price {
  /* color: red; */
  text-decoration: line-through;
}
.v-list-item-subtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
}
.price::before {
  content: "฿ ";
}
::v-deep .v-list-item__content {
  align-self: center;
  grid-area: content;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
</style>
