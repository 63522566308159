<template>
  <v-dialog
    v-model="modalShow"
    hide-footer
    centered
    hide-header
    persistent
    no-close-on-backdrop
    no-close-on-esc
    @hidden="$emit('hidden', index + 1)"
    body-class="p-0"
    content-class="border-none"
    scroll-strategy="none"
  >
    <div>
      <v-img :src="data.image_banner" cover aspect-ratio="1"> </v-img>
      <c-btn block variant="primary-theme" @click="addAnswer()" class="mt-2">
        {{ data.button_label }}
      </c-btn>
      <c-btn
        block
        variant="custom-link"
        @click="(modalShow = false), $emit('hidden', index + 1)"
      >
        <span data-lang="skip">{{ $t("skip") }}</span>
      </c-btn>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    data: { required: true },
    index: {
      required: true
    }
  },
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    hide() {
      this.modalShow = false;
    },
    addAnswer() {
      let id = this.$cookies.get("modalShowId") || [];

      this.$cookies.set("modalShowId", [...id, this.data.id]);
      this.$router.push("/form/" + this.data.id);
    }
  }
};
</script>

<style></style>
