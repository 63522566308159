<template>
  <div class="div-input">
    <div class="display-only" v-if="disabled"></div>

    <div
      :id="'input-' + name"
      class="new-theme-input"
      :class="[
        'input-custom relative',
        {
          error: eachValid != null ? eachValid.$error && isValidate : isValidate
        }
      ]"
    >
      <v-text-field
        :class="inputClass"
        :label="labelName || placeholder"
        :placeholder="placeholder"
        :type="type.toLowerCase()"
        :name="name"
        :required="required"
        v-model="computedValue"
        :size="size"
        @change="onDataChange"
        @keypress="$emit('onkeypress', $event)"
        @paste="$emit('onPaste', $event)"
        @keyup="$emit('onkeyup', $event.target.value)"
        ref="input"
        :maxlength="maxlength"
        :readonly="readonly"
        :disabled="disabled"
        :oninput="oninput"
        :pattern="type.toLowerCase() == 'number' ? '[0-9]*' : false"
        :inputmode="type.toLowerCase() == 'number' ? 'numeric' : inputmode"
        :hint="hint"
        :persistentHint="hint ? true : false"
        :error="v && v.$error"
        :hide-details="hint ? false : true"
      />
      <slot></slot>
    </div>
    <span v-if="detail" class="text-desc">{{ detail }}</span>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: { required: true, type: String },
    placeholder: { required: true, type: String },
    type: { required: true, type: String, default: "text" },
    required: { required: false, type: Boolean },
    detail: { required: false, type: String },
    size: { required: false, type: String },
    name: { required: false, type: String },
    value: { required: false, type: [String, Number] },
    disabled: { required: false, type: Boolean },
    isRequired: { required: false, type: Boolean },
    isValidate: { required: false, type: Boolean },
    maxlength: { required: false, type: [Number, String, Boolean] },
    oninput: {
      required: false,
      type: String,
      default:
        "javascript: if (this.value.length > 255) this.value = this.value.slice(0, 255);"
    },
    inputmode: { required: false, type: String },
    hint: { required: false, default: "", type: String },
    persistentHint: { required: false, default: false, type: Boolean },

    className: {
      required: false,
      type: String
    },

    v: {
      required: false,
      type: Object
    },

    textRemark: {
      required: false,
      type: String
    },
    validateText: {
      required: false
    },
    eachValid: {
      required: false,
      type: Object,
      default: null
    },
    index: {
      required: false,
      type: Number,
      default: 0
    },
    readonly: {
      required: false,
      default: false,
      type: Boolean
    },
    isNotValidate: {
      required: false,
      default: false,
      type: Boolean
    },
    inputClass: {
      required: false,
      default: "",
      type: String
    },
    skip: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("update:value", newValue);
      }
    },
    labelName() {
      if (this.v && !this.skip) {
        if (this.v.$error) {
          if (this.isNotValidate) return this.textFloat;
          else return this.textFloat + " " + this.getValidationError() + " *";
        } else if (this.isRequired) return this.textFloat + " *";
      } else return this.textFloat;
    }
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value, event);
    },
    focus() {
      this.$refs.input.focus();
    },
    getValidationError(v = this.v, validateText = this.validateText, $t) {
      if (!v || !v.$error || (this.eachValid && !this.eachValid.$error))
        return "";

      const errorMessages = [
        {
          condition: (v.required && v.required.$invalid) || !v.required,
          message: validateText || this.$t("default_input_validate")
        },
        {
          condition: v.minLength && v.minLength.$invalid,
          message: `${this.$t("validate_input_min_length")} ${
            this.eachValid
              ? this.eachValid.$each.$response.$errors[this.index].value[0]
                  .$params.min
              : v.minLength && v.minLength.$params.min
          } ${this.$t("character")}`
        },
        {
          condition: v.email && v.email.$invalid,
          message: this.$t("validate_email")
        },
        {
          condition: v.customEmail && v.customEmail.$invalid,
          message: this.$t("validate_email")
        },
        {
          condition:
            (v.integer && v.integer.$invalid) ||
            (v.decimal && v.decimal.$invalid) ||
            (v.minValue && v.minValue.$invalid),
          message: this.$t("validate_input_number")
        },
        {
          condition: v.maxLength && v.maxLength.$invalid,
          message: `${this.$t("validate_input_max_length")} ${
            this.eachValid
              ? this.eachValid.$each.$response.$errors[this.index].value[0]
                  .$params.max
              : v.maxLength && v.maxLength.$params.max
          } ${this.$t("character")}`
        },
        {
          condition: v.alpha && v.alpha.$invalid,
          message: this.$t("validate_english_only")
        },
        {
          condition: v.alphaNumEng && v.alphaNumEng.$invalid,
          message: this.$t("validate_english_num_only")
        },
        {
          condition: v.Script_checkID && v.Script_checkID.$invalid,
          message: this.$t("validate_id_card")
        }
      ];

      const error = errorMessages.find(e => e.condition);
      console.log(error);
      return error ? error.message : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.text-desc {
  color: rgba(22, 39, 74, 0.4);
  font-size: var(--text-sm);
}
</style>
