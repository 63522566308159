<template>
  <div class="marketplace-banner-container">
    <carousel
      :wrapAround="true"
      :transition="500"
      :mouseDrag="lengthBanner > 1"
      :touchDrag="lengthBanner > 1"
      :items-to-show="lengthBanner <= 1 ? 1 : 1.1"
      :autoplay="lengthBanner > 1 ? 5000 : false"
      snapAlign="center"
    >
      <slide
        v-for="items of filterBanner"
        :key="items.id + 'banner-marketplace'"
        :class="lengthBanner <= 1 ? '' : 'px-2'"
      >
        <img
          class="d-block img-fluid w-100"
          :src="items.banner"
          alt="image slot"
          @click="
            $router.push(
              `/connectmarketplace?b_id=${items.branchId}&s_id=${items.salesChannelId}`
            )
          "
        />
      </slide>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
export default {
  components: {
    Carousel,
    Slide
  },

  props: {
    marketPlaceObj: {
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      newsList: [],

      interval: 3000,
      filterNews: {
        page: 1,
        take: 10,
        id: []
      }
    };
  },
  mounted() {},
  computed: {
    filterBanner() {
      return this.marketPlaceObj.marketplace
        ? this.marketPlaceObj.marketplace.filter(
            el => el.status == 0 && el.banner
          )
        : [];
    },
    lengthBanner() {
      return this.marketPlaceObj.marketplace
        ? this.marketPlaceObj.marketplace.filter(
            el => el.status == 0 && el.banner
          ).length
        : 0;
    }
  },
  methods: {
    // async getNewsList() {
    //   await this.$axios
    //     .post(`/api/v1/MonthlyPrivilege/list`, this.filterNews)
    //     .then(async data => {
    //       // this.isLoading = false;
    //       if (data.result == 1) {
    //         this.newsList = data.detail.detail;
    //         this.isLoading = false;
    //       }
    //     });
    // },
    // getForm(item, index) {
    //   this.$router.push("/news/" + item.id);
    // }
  }
};
</script>

<style>
.marketplace-banner-container {
  margin: 0 0.5rem 0.5rem 0.5rem;
}
</style>
