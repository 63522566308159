<template>
  <div>
    <label>
      {{ textFloat }}
      <span v-if="isRequired" class="text-danger">*</span>
    </label>
    <v-textarea
      :placeholder="placeholder"
      :value="value"
      :error="isValidate"
      @update:modelValue="$emit('update:value', $event)"
      single-line
      hide-details
    ></v-textarea>
  </div>
  <img :src="img" alt="logo-lang" v-if="img" class="logo-lang" />
  <span v-if="detail" class="text-desc pl-2">{{ detail }}</span>
  <template v-if="eachValid != null">
    <div v-if="eachValid.$error && v && v.$error">
      <span class="text-error" v-if="v.required.$invalid">{{
        validateText || $t("default_input_validate")
      }}</span>
      <span class="text-error" v-else-if="!v.required">{{
        validateText || $t("default_input_validate")
      }}</span>
      <span class="text-error" v-else-if="v.minValue && v.minValue.$invalid">{{
        options[0][textField || "text"]
      }}</span>
      <span
        class="text-error"
        v-if="v.valueDefault && v.valueDefault.$invalid"
        >{{ options[0][textField || "text"] }}</span
      >
    </div>
  </template>
  <template v-else>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required.$invalid || !v.required">{{
        validateText || $t("default_input_validate")
      }}</span>

      <span class="text-error" v-else-if="v.minValue && v.minValue.$invalid">{{
        options[0][textField || "text"]
      }}</span>
      <span
        class="text-error"
        v-if="v.valueDefault && v.valueDefault.$invalid"
        >{{ options[0][textField || "text"] }}</span
      >
    </div>
  </template>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: true,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: String
    },
    rows: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    disabled: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    img: {
      required: false,
      type: String
    },
    validateText: {
      required: false,
      type: String
    },
    eachValid: {
      required: false,
      default: null
    }
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value);
    }
  }
};
</script>

<style scoped>
.div-input {
  /* margin-bottom: 15px; */
  position: relative;
  white-space: nowrap;
}
.input-custom {
  padding: 0px;
}

.input-custom > textarea {
  color: #16274a;
  background-color: white;
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
}
.input-custom > textarea:focus {
  border: 1px solid #16274a;
}
.input-custom.error > textarea {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: #16274a;
  font-size: 0.8rem;
  font-family: "Kanit-Light";
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
</style>
