<template>
  <div class="content-edit-profile">
    <v-row no-gutters style="row-gap: 10px">
      <template v-for="(field, index) in fields" :key="'dynamic-' + index">
        <v-col cols="12" :id="'field-' + fieldName(field)">
          <div v-if="field.field_type_id == 1">
            <InputText
              v-if="!isLoading"
              :textFloat="fieldName(field)"
              :placeholder="fieldName(field)"
              class="margin-inputf-regular"
              :type="checkFieldConditon(field)"
              name="firstname"
              size="lg"
              v-model:value="field[keyValue]"
              :maxlength="checkMaxMin(field.field_conditions, 2)"
              :min="checkMaxMin(field.field_conditions, 3)"
              :max="checkMaxMin(field.field_conditions, 4)"
              :isRequired="field[keyRequired]"
              :disabled="field.field_profile_type_id == 10"
              @onDataChange="isMaxMin"
              :v="v$[keyFormValidate].$each.$response.$data[index][keyValue]"
              :eachValid="v$[keyFormValidate]"
              :index="index"
              :validateText="field.validate_text"
              :isValidate="
                v$[keyFormValidate].$error &&
                v$[keyFormValidate].$each.$response.$data[index][keyValue]
                  .$error
              "
              @onkeypress="isNumber"
            />
          </div>

          <div
            v-else-if="
              field.field_type_id == 2 && field.field_profile_type_id != 17
            "
          >
            <div class="input-custom">
              <label>
                {{ fieldName(field) }}

                <span v-if="field[keyRequired]" class="text-danger">*</span>
              </label>

              <InputCheckbox
                :id="`${field.id}`"
                :options="field.field_choices"
                value-field="name"
                v-model:value="field[keyValue]"
                :value="field[keyValue]"
                text-field="name"
                stacked
                :index="index"
                :eachValid="v$[keyFormValidate]"
                :v="v$[keyFormValidate].$each.$response.$data[index][keyValue]"
                :validateText="field.validate_text"
                :isValidate="
                  v$[keyFormValidate].$error &&
                  v$[keyFormValidate].$each.$response.$data[index][keyValue]
                    .$error
                "
              />
            </div>
            <div
              v-if="
                v$[keyFormValidate].$error &&
                v$[keyFormValidate].$each.$response.$data[index][keyValue]
                  .$error
              "
            >
              <small class="text-error">{{ field.validate_text }}</small>
            </div>
          </div>
          <div v-else-if="field.field_profile_type_id == 17">
            <div class="input-custom">
              <label>
                {{ fieldName(field) }}

                <span v-if="field[keyRequired]" class="text-danger">*</span>
              </label>

              <InputCheckbox
                :id="`${field.id}`"
                :options="[{ name: 'Set to default', value: 1 }]"
                value-field="value"
                v-model:value="field[keyValue]"
                :value="field[keyValue]"
                text-field="name"
                :falseValue="0"
                stacked
              />
            </div>
            <div
              v-if="
                v$[keyFormValidate].$error &&
                v$[keyFormValidate].$each.$response.$data[index][keyValue]
                  .$error
              "
            >
              <small class="text-error">{{ field.validate_text }}</small>
            </div>
          </div>
          <div v-else-if="field.field_type_id == 3">
            <div class="input-custom">
              <label>
                {{ fieldName(field) }}
                <span v-if="field[keyRequired]" class="text-danger">*</span>
              </label>
              <InputRadio
                :id="`${field.id}`"
                :options="field.field_choices"
                value-field="name"
                text-field="name"
                v-model:value="field[keyValue]"
                :index="index"
                :eachValid="v$[keyFormValidate]"
                :v="v$[keyFormValidate].$each.$response.$data[index][keyValue]"
                :validateText="field.validate_text"
                :isValidate="
                  v$[keyFormValidate].$error &&
                  v$[keyFormValidate].$each.$response.$data[index][keyValue]
                    .$error
                "
              ></InputRadio>
            </div>
          </div>
          <div v-else-if="field.field_type_id == 4" class="margin-input">
            <InputSelect
              :title="fieldName(field)"
              :text="fieldName(field)"
              :name="fieldName(field)"
              :placeholder="fieldName(field)"
              valueField="name"
              textField="name"
              v-bind:options="field.field_choices"
              v-model:value="field[keyValue]"
              :isRequired="field[keyRequired]"
              class="f-regular"
              :index="index"
              :eachValid="v$[keyFormValidate]"
              :v="v$[keyFormValidate].$each.$response.$data[index][keyValue]"
              :validateText="field.validate_text"
              :isValidate="
                v$[keyFormValidate].$error &&
                v$[keyFormValidate].$each.$response.$data[index][keyValue]
                  .$error
              "
              formType
            />
          </div>
          <div v-else-if="field.field_type_id == 5">
            <label class="main-label">
              {{ fieldName(field) }}
              <span v-if="field[keyRequired]" class="text-danger">*</span>
            </label>

            <datetime
              type="datetime"
              :placeholder="fieldName(field)"
              :class="['date-picker-input date-filter']"
              :format="'dd MMM yyyy HH:mm'"
              value-zone="Asia/Bangkok"
              v-model:value="field[keyValue]"
              :isValidate="
                v$[keyFormValidate].$error &&
                v$[keyFormValidate].$each.$response.$data[index][keyValue]
                  .$error
              "
            ></datetime>
            <div
              v-if="
                v$[keyFormValidate].$error &&
                v$[keyFormValidate].$each.$response.$data[index][keyValue]
                  .$error
              "
            >
              <small class="text-error">{{ field.validate_text }}</small>
            </div>
          </div>
          <div v-else-if="field.field_type_id == 6" class="input-custom">
            <InputSelect
              :title="fieldName(field)"
              :text="fieldName(field)"
              :name="fieldName(field)"
              :placeholder="fieldName(field)"
              valueField="name"
              textField="name"
              v-bind:options="branchList"
              v-model:value="field[keyValue]"
              :isRequired="field[keyRequired]"
              class="f-regular"
              :v="v$[keyFormValidate].$each.$response.$data[index][keyValue]"
              :index="index"
              :eachValid="v$[keyFormValidate]"
              :validateText="field.validate_text"
              :isValidate="
                v$[keyFormValidate].$error &&
                v$[keyFormValidate].$each.$response.$data[index][keyValue]
                  .$error
              "
              formType
            />
          </div>

          <div v-else-if="field.field_type_id == 7">
            <div class="input-custom">
              <label>
                {{ fieldName(field) }}

                <span v-if="field[keyRequired]" class="text-danger">*</span>
              </label>

              <datetime
                :placeholder="fieldName(field)"
                :class="['date-picker-input date-filter']"
                :format="'dd MMM yyyy HH:mm'"
                value-zone="Asia/Bangkok"
                v-model:value="field[keyValue]"
                :isValidate="
                  v$[keyFormValidate].$error &&
                  v$[keyFormValidate].$each.$response.$data[index][keyValue]
                    .$error
                "
              ></datetime>
              <div
                v-if="
                  v$[keyFormValidate].$error &&
                  v$[keyFormValidate].$each.$response.$data[index][keyValue]
                    .$error
                "
              >
                <small class="text-error">{{ field.validate_text }}</small>
              </div>
            </div>
          </div>
          <div v-else-if="field.field_type_id == 8" class="input-custom">
            <template v-if="index == 0 && isCustomObject">
              <label v-if="fields.name">
                {{ fields.name }}

                <span
                  v-if="fields.is_required == 1 ? true : false"
                  class="text-danger"
                  >*</span
                >
              </label>

              <FormUploadImage
                :id="field.id"
                :label="fieldName(field)"
                :img="field[keyValue]"
                v-model:value="field[keyValue]"
                :v="v$[keyFormValidate].$each.$response.$data[index][keyValue]"
                :validateText="field.validate_text"
                :isValidate="
                  v$[keyFormValidate].$error &&
                  v$[keyFormValidate].$each.$response.$data[index][keyValue]
                    .$error
                "
                @deleteImage="deleteImage(index)"
              />
              <div
                v-if="
                  v$[keyFormValidate].$error &&
                  v$[keyFormValidate].$each.$response.$data[index][keyValue]
                    .$error
                "
              >
                <small class="text-error">{{ field.validate_text }}</small>
              </div>
            </template>
            <template v-else>
              <label>
                {{ fieldName(field) }}
                <span v-if="field[keyRequired]" class="text-danger">*</span>
              </label>

              <CustomInputUploadImage
                :id="field.id"
                :label="fieldName(field)"
                :img="field[keyValue]"
                v-model:value="field[keyValue]"
                :v="v$[keyFormValidate].$each.$response.$data[index][keyValue]"
                :validateText="field.validate_text"
                :isValidate="
                  v$[keyFormValidate].$error &&
                  v$[keyFormValidate].$each.$response.$data[index][keyValue]
                    .$error
                "
              />
              <div
                v-if="
                  v$[keyFormValidate].$error &&
                  v$[keyFormValidate].$each.$response.$data[index][keyValue]
                    .$error
                "
              >
                <small class="text-error">{{ field.validate_text }}</small>
              </div>
            </template>
          </div>

          <div v-else-if="field.field_type_id == 10">
            <h2>{{ fieldName(field) }}</h2>
          </div>
          <div v-else-if="field.field_type_id == 11">
            <h5>{{ fieldName(field) }}</h5>
          </div>
          <div v-else-if="field.field_type_id == 12">
            <InputTextArea
              :id="field.id"
              :textFloat="fieldName(field)"
              type="text"
              class="mt-3 custom-input"
              :placeholder="fieldName(field)"
              rows="3"
              v-model:value="field[keyValue]"
              :isRequired="field[keyRequired]"
              :eachValid="v$[keyFormValidate]"
              :validateText="field.validate_text"
              :v="v$[keyFormValidate].$each.$response.$data[index][keyValue]"
              :isValidate="
                v$[keyFormValidate].$error &&
                v$[keyFormValidate].$each.$response.$data[index][keyValue]
                  .$error
              "
            />
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import FormUploadImage from "@/components/input/FormUploadImage";

import InputTextArea from "@/components/input/InputTextArea";
import CustomInputUploadImage from "@/components/input/CustomInputUploadImage";
export default {
  components: {
    InputTextArea,
    CustomInputUploadImage,
    FormUploadImage
  },
  props: {
    v$: {
      required: false
    },
    fields: {
      required: true
    },
    keyValue: {
      type: String,
      default: () => "user_answer",
      required: false
    },
    keyFormValidate: {
      required: false,
      type: String,
      default: () => "dynamicField"
    },
    keyRequired: {
      required: false,
      type: String,
      default: () => "required"
    },
    isCustomObject: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return { branchList: [] };
  },
  created() {
    let branchField = this.fields.find(el => el.field_type_id == 6);
    if (branchField) {
      this.getBranch(branchField.branch_group_id);
    }
  },
  methods: {
    checkMaxMin(item, key, field_id) {
      if (!item) return false;
      if (field_id == 9) {
        const condition = item.find(el => el.field_condition_type_id == 7);

        if (condition)
          if (key == 3) {
            return condition.value
              ? this.$moment()
                  .subtract(condition.value, "year")
                  .format("YYYY-01-01")
              : false;
          } else if (key == 4) return this.$moment().format("YYYY-MM-DD");
      }
      const conditon = item.find(el => el.field_condition_type_id == key);
      if (conditon) return conditon.value ? conditon.value : false;
      return false;
    },
    deleteImage(index) {
      this.fields[index].value = "";
    },
    isMaxMin: function (value, e) {
      try {
        const val = e.target.value;

        const max = Number(e.target.max);
        const min = Number(e.target.min);
        if (val && e.target.type == "number" && min && val < min) {
          return (e.target.value = min);
        }
        if (val && e.target.type == "number" && max && val > max) {
          return (e.target.value = max);
        }
      } catch (error) {
        console.log(error);
      }
    },
    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        e.target.type == "number"
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },
    checkFieldConditon(obj) {
      if (!obj.field_conditions) return "text";
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    checkIsProfile(val) {
      const findField = fieldInForm2.find(el => el.id == val);

      return findField ? findField.key : false;
    },
    checkField() {},
    async getBranch(group_id) {
      this.$axios.get(`/api/v1/Reward/redeem_coupon/${group_id}`).then(
        async data => {
          this.isLoading = false;
          if (data.result == 1) {
            var branchList = [];
            for (const { branch } of data.detail) {
              const filter = branch.filter(el => el.is_check);
              branchList.push(...filter);
            }
            this.branchList = branchList;
          }
        },
        error => {
          const code = parseInt(error.response && error.response.status);
          if (code === 401) {
            console.log(error);
          }
        }
      );
    },
    fieldName($fields) {
      if ($fields.field_language)
        if ($fields.field_language.length > 0)
          return $fields.field_language[0].name || $fields.name;
        else return $fields.name;
      else return $fields.name;
    }
  }
};
</script>

<style></style>
