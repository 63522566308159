<template>
  <div>
    <v-dialog
      v-model="showModal"
      ref="ModalQRUser"
      hide-footer
      centered
      @hidden="hide"
      scroll-strategy="none"
      content-class="rounded-4 px-3 pb-6 pt-2"
    >
      <div class="text-right mb-2" v-if="lineLayout.includes('Minimal')">
        <font-awesome-icon icon="times" @click="hide"></font-awesome-icon>
      </div>
      <div>
        <div class="text-center">
          <div v-if="lineLayout.includes('Minimal')" class="mb-3">
            {{ $t("member_qr_title") }}
          </div>
          <qrcode-vue
            :class="
              lineLayout.includes('Minimal') ? 'qr-width-50' : 'qr-width-70'
            "
            render-as="svg"
            :value="value"
            background="transparent"
          />
          <div v-if="lineLayout.includes('Minimal')" class="mb-2">
            {{
              $store.state.theme.qr_code == "Telephone"
                ? $store.state.shortProfile.telephone
                : $store.state.shortProfile.member_id
            }}
          </div>
        </div>
      </div>
      <!-- <div class="border-top p-2 text-center btn-confirm-popup">
        <div @click="hide">ปิดหน้าต่างนี้</div>
      </div> -->
      <c-btn
        block
        variant="confirm-primary"
        @click="hide"
        v-if="!lineLayout.includes('Minimal')"
      >
        >ปิดหน้าต่างนี้</c-btn
      >
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: String
    }
  },
  components: {},
  mounted() {},
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.f-bold {
  font-weight: 600;
  font-size: var(--text-xl);
  padding: 10px 0;
}
.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid #000;
  border-radius: 50%;
  bottom: 0px;
}
.title-profile {
  font-weight: 600;
  font-size: var(--text-xl);
}
.border-behind {
  border-top: 4px dashed #dfdfdf;
  position: absolute;
  top: 50px;
  width: 100%;
  left: 0;
}
.img-zindex {
  z-index: 1;
  position: relative;
}
.dot {
  display: inline-block;
  top: 30%;
  position: absolute;
  width: 20px;
  height: 35px;
  border-left: 0;
  background-color: gray;
}
.dot-left {
  margin-left: -17px;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
.dot-right {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  margin-right: -16px;
}
.color-gray-ft {
  color: #9d9d9d;
}
.qr-width-70 {
  width: 80% !important;
  height: 80% !important;
  padding: 0.5rem 0;
}
.qr-width-50 {
  width: 50% !important;
  height: 50% !important;
  padding: 0.5rem 0;
}
</style>
